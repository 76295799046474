import React, { useEffect, useState, useCallback } from 'react';
import Web3 from 'web3';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal from 'web3modal';
import { TTR_ABI } from '../web3/abi';

import { WalletContext } from './wallet-context';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: '4d1be59a8a154a05924e31eafc6e02f8', // required
    },
  },
};

const web3Modal = new Web3Modal({
  // cacheProvider: true, // optional
  providerOptions, // required
});

export const WalletProvider = ({ children }) => {
  const [web3js, setWeb3js] = useState();
  const [TTR, setTTR] = useState();
  const [address, setAddress] = useState();
  const [connected, setConnected] = useState(false);
  const [connection, setConnection] = useState();

  /**
   * Function to call on click to trigger connection modal.
   */
  const handleConnection = useCallback(async () => {
    try {
      const provider = await web3Modal.connect();
      const web3 = new Web3(provider);

      setConnection(provider);
      setAddress(provider.selectedAddress ?? provider?.accounts[0]);
      setWeb3js(web3);
      setConnected(true);
    } catch (e) {
      console.log(e);
    }
  }, [setWeb3js, setAddress, setConnection, setConnected]);

  /**
   * Helper function to dry setting up connect/state
   */
  const connectWallet = useCallback(handleConnection, [handleConnection]);

  /**
   * Set TTR contract web3 interface. Stores in state.
   */
  useEffect(() => {
    if (web3js) {
      const _TTR = new web3js.eth.Contract(
        TTR_ABI,
        '0xFd6FCC7193736c6c6fef753AB17D92D92f3E465B',
      );
      setTTR(_TTR);
    }
  }, [setTTR, web3js]);

  /**
   * Reconnect on load if provider is cached.
   */
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      handleConnection();
    }
  }, []);

  /**
   * Handle when a user disconnects wallet.
   */
  useEffect(() => {
    if (connection) {
      connection.on('disconnect', () => {
        setConnection();
        setAddress();
        setWeb3js();
        setTTR();
        setConnected();
      });
    }
  }, [connection, setConnection, setAddress, setWeb3js, setTTR, setConnected]);

  return (
    <WalletContext.Provider value={{
      address, connected, TTR, connectWallet, web3js
    }}
    >
      {children}
    </WalletContext.Provider>
  );
};
