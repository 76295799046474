import React from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
// import iphoneRender from '../../assets/img/Iphone-render.png';
import iphoneRender from '../../assets/img/ttr-iphone-mayc-home.png';
import iphoneRenderMobile from '../../assets/img/Iphone-render-mobile-min.png';

const StyledIphone = styled.img`
  width: 40vh;
  z-index: 10;

  @media(max-width: 720px) {
    width: 29vh;
  }
`;

export const IphoneHome = ({ className, show }) => {
  const duration = 1000;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 1,
  };

  const transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition in={show} timeout={duration}>
      {(state) => (
        <StyledIphone
          className={className}
          src={window.innerWidth < 720 ? iphoneRender : iphoneRender}
          alt="Iphone Game Render"
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        />
      )}
    </Transition>
  );
};
