import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
// import { Web3 } from 'web3';
import {
  AppleAppStore,
  FacebookOption,
  Twitter,
  Instagram,
  LinkDown,
} from 'grommet-icons';
import { globalColors } from '@ttr/tokens/colors';
import {
  AnimatedContainer,
  Copy,
  Logo,
  Iphone,
  IphoneHome,
  IphoneCash,
} from '..';
import background from '../../assets/img/ttr-dot-com-bg-2.svg';
import backgroundMobile from '../../assets/img/ttr-dot-com-bg-mobile-min.jpg';
import appStoreIcon from '../../assets/img/appstore-icon-apple.svg';
import { useWeb3 } from '../../hooks/use-web3';

const StyledMain = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: url(${background});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    padding-bottom: 200px;
    max-height: 840px;
    height: 100%;
    width: 100%;
    z-index: 2;

    @media(max-width: 720px) {
      background-image: url(${backgroundMobile});
      padding-bottom: 100px;
    }
  `;

export const Web3Main = () => {
  const [greeting, setGreeting] = useState('');
  // const [loading, setLoading] = useState(false);
  const {
    account, connected, handleConnectMetaMask, web3js, loading, TTR,
  } = useWeb3();

  const handleSetBaseURI = async () => {
    await TTR.methods.tokenURI(1).call({ from: account })
      .on('receipt', (receipt) => {
        console.log(receipt);
        alert('URIED', receipt);
      }).on('error', (error) => {
        console.log(error);
      });
  };

  const handleToggleSale = async () => {
    await TTR.methods.flipSaleState().send({ from: account })
      .on('receipt', (receipt) => {
        console.log(receipt);
        alert('Flipped', receipt);
      }).on('error', (error) => {
        console.log(error);
      });
  };

  const handleMint = async () => {
    await TTR.methods.reserveTTRs().send({ from: account })
      .on('receipt', (receipt) => {
        console.log(receipt);
        alert('Minted', receipt);
      }).on('error', (error) => {
        console.log(error);
      });
  };

  const handleGetTTRId = async () => {
    const ttrId = await TTR.methods.tokenIdsOfOwner(account).call({ from: account });
    console.log(TTR);
  };

  if (loading) return null;
  handleGetTTRId();
  return (
    <div className="App">
      {!connected && <button onClick={handleConnectMetaMask}>Enable Ethereum</button>}
      {connected && (
        <>
          <button onClick={handleSetBaseURI}>SetURI</button>
          <button onClick={handleToggleSale}>ToggleSale</button>
          <button onClick={handleMint}>MINT</button>
        </>
      )}
    </div>
  );
};
