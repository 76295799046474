import React from 'react';
import {
  Link,
} from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/img/logo.svg';

const StyledContainer = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  padding: 0 40px;
`;

export const PrivacyPolicy = () => (
  <StyledContainer>
    <nav>
      <div className="logo-wrapper">
        <Link to="/"><img className="logo-nav" src={logo} alt="TapTapRev Logo" /></Link>
      </div>
    </nav>

    <main stlye={{ padding: '40px 0' }}>
      <div className="legal-container">
        <h2 className="legal-header">TapTapRev Privacy Policy</h2>
        <p className="legal-copy">
          This Privacy Policy describes the policies and procedures of Marvly LLC (“we”, “our” or “us”) on the
          collection, use and disclosure of your information in connection with your use of the HQ application
          (the “App”) and any associated services, features, content or applications we offer through the App
          (collectively, the “Services”). We receive information about you from various sources, including without
          limitation: (i) if you register for the App and the Services, through your user account on the Services
          (your “Account”); (ii) your use of the Services generally; and (iii) from third party websites and
          services. When you use the Services, you are consenting to the collection, transfer, manipulation,
          storage, disclosure and other uses of your information as described in this Privacy Policy.
        </p>

        <p className="legal-copy">
          What Does This Privacy Policy Cover?
          This Privacy Policy covers the treatment of personally identifiable information (“Personal Information”)
          gathered when you are using or accessing the Services. This Privacy Policy also covers our treatment of
          any Personal Information that our business partners share with us or that we share with our business
          partners.
        </p>

        <p className="legal-copy">
          This Privacy Policy does not apply to the practices of third parties that we do not
          own or control,
          including but not limited to any third party websites, services and applications (e.g. Spotify, iTunes,
          YouTube, Google Play, Twitter, Facebook, Instagram, etc.) (each a “Third Party Service”) that you elect
          to access through the Service or to individuals that we do not manage or employ. While we attempt to
          facilitate access only to those Third Party Services that share our respect for your privacy, we cannot
          take responsibility for the content or privacy policies of those Third Party Services. We encourage you
          to carefully review the privacy policies of any Third Party Services you access.
        </p>

        <p className="legal-copy">
          What Information Do We Collect?
          The information we gather enables us to personalize, improve and continue to operate the Services. In
          connection with certain aspects of the Services, we may request, collect and/or display some of your
          Personal Information. We collect the following types of information from our users.
        </p>

        <p className="legal-copy">
          Account Information:
          If you create an account on the Services (“Account”), you may be required to provide information that
          could be Personal Information, such as your name, phone number and/or email address. Additionally, if
          you register for or access the Services using a Third Party Service (such as your social media account
          login credentials), we may receive Personal Information that you allow such Third Party Service to share
          with us.  You acknowledge that this information may be personal to you, and by creating an Account and
          providing Personal Information to us, you allow others, including us, to identify you and therefore may
          not be anonymous. We may use your contact information to send you information about our Services. You
          may unsubscribe from some of these messages by emailing us at nathan@taptaprevolution.com , although we,
          regardless, reserve the right to contact you when we believe it is necessary, such as for account
          recovery purposes.
        </p>

        <p className="legal-copy">
          Information Regarding Your Social Networks:

          As mentioned above, you may be able to register for or access the Services using a Third Party Services,
          such as your social media account (e.g. Facebook, Twitter, Instagram, etc.).  Occasionally, you can also
          use your Account on our Services to interact with your accounts on these other services.  For example,
          you may be able to access posting and sharing tools on the Services that allow you to post information
          to your social networks outside of the Services (“Share”).  By using these tools, you acknowledge that
          some account information from your account on the applicable Third Party Service may be transmitted into
          your Account with us, and that such information is covered by this Privacy Policy.  Furthermore, if a
          tool, such as the Share tool, is operated by a Third Party Service, the Third Party Service that
          operates the tool may collect information about your browser or online activity, which would be subject
          to the Third Party Service’s privacy policy.  When you use these tools, some of your information from
          the Services may be shared with the Third Party Service and others.  Therefore, we encourage you to read
          the privacy policies and other policies of any Third Party Services, including without limitation any
          applicable social networks, you use in connection with the Services.
        </p>

        <p className="legal-copy">
          Additionally, we may collect and receive your social media account login credentials
          in connection with
          providing you with the Services, but only if you expressly provide us with such information. We will
          only use such login information as set forth in this Privacy Policy or as otherwise expressly authorized
          by you.
        </p>

        <p className="legal-copy">
          User Content:

          Some features of the Services allow you to provide content to the Services, such as video clips. All
          content submitted by you to the Services may be retained by us indefinitely, even after you terminate
          your Account. We may continue to disclose such content to third parties in a manner that does not reveal
          Personal Information, as described in this Privacy Policy.
        </p>

        <p className="legal-copy">
          Location Information:
          Some of our Services may include features based on users’ actual locations and may report on users’
          current locations (“Location-Based Services”).  To the extent you voluntarily opt to use any
          Location-Based Services, we may collect and store information about your location at the time you use
          those Location-Based Services. Additionally, we may also collect and/or receive location information
          from your use of the Services generally, which can come from a variety of sources, depending on how you
          use or access the Services. For example, a User Image taken from your mobile device may contain location
          information related to where the image was taken, or a mobile device may report its GPS location at the
          time Location-Based Services are used.
        </p>

        <p className="legal-copy">
          IP Address Information and Other Information Collected Automatically:
          Generally, the Services automatically collect usage information, such as the number and frequency of
          visitors to the App and users of the Services. We may use this data in aggregate form, that is, as a
          statistical measure, or in other anonymous forms, but not in a manner that would identify you
          personally. This type of data enables us and third parties authorized by us to figure out how often
          individuals use parts of the Services so that we can analyze and improve them.
          We automatically receive and record information from your web browser and device when you interact with
          the Services, including your IP address and cookie information and other mobile identifiers. This
          information is used for fighting spam/malware and also to facilitate collection of data concerning your
          interaction with the Services (e.g., what links you have clicked on).
          We may collect some device-specific information if you access the Services using a mobile device.
          Device information may include but is not limited to unique device identifiers, network information,
          and hardware model, as well as information about how the device interacts with our Services.
          Email, Text, and Push Notification Communications:
          We may communicate with you via push notification, email, SMS, MMS or other text message (“Message”) (to
          the extent you permit us to do so), and we may collect information regarding such communications, such
          as confirmation when you open an email, read a Message or receive a push notification. We use this
          information to improve our Services, including without limitation our customer service.
          Aggregate Information[a]:
          We collect statistical information about how both unregistered and registered users, collectively, use
          the Services (“Aggregate Information”). Some of this information is derived from Personal Information.
          This statistical information is not Personal Information and cannot be tied back to you, your Account or
          your web browser.
          How, and With Whom, Is My Information Shared[b]?
          Public Information About Your Activity on the Services:
        </p>

        <p className="legal-copy">
          Some of your activity on and through the Services is public. This may include, but is
          not limited to,
          content you have posted publicly on, through or in connection with the Services, including without
          limitation on public portions of Third Party Services, such as your social media accounts.
        </p>

        <p className="legal-copy">
          Registered users may have some of this information associated with their Accounts.
          Unregistered users
          will not have this association, but information concerning their use of the Services (such as what pages
          they have visited) may be tracked anonymously through the use of cookies and stored by us.
        </p>

        <p className="legal-copy">
          Please remember that if you choose to provide Personal Information using certain
          public features of the
          Services, then that information is governed by the privacy settings of those particular features and may
          be publicly available. Individuals reading such information may use or disclose it to other individuals
          or entities without our control and without your knowledge, and search engines may index that
          information. We therefore urge you to think carefully about including any specific information you may
          deem private in content that you create or information that you submit through the Services.
        </p>

        <p className="legal-copy">IP Address and Device Information:</p>

        <p className="legal-copy">
          While we collect and store IP address and device information, that information is not
          made public. We do
          at times, however, share this information with our partners, service providers and other persons with
          whom we conduct business, and as otherwise specified in this Privacy Policy.
        </p>

        <p className="legal-copy">Information You Elect to Share:</p>

        <p className="legal-copy">
          You may access other Third Party Services through the Services, for example by
          clicking on links to
          those Third Party Services from within our Services. We are not responsible for the privacy policies
          and/or practices of these Third Party Services, and you are responsible for reading and understanding
          those Third Party Services’ privacy policies. This Privacy Policy only governs information collected on
          the Services.
        </p>

        <p className="legal-copy">
          Aggregate and Non-Personally Identifiable Information:

          We share Aggregate Information with our partners, service providers and other persons with whom we
          conduct business (“Business Partners”).  We may also share information with our Business Partners that
          is (i) not Personal Information or (ii) derived from Personal Information but has been anonymized so
          that it is no longer personally identifiable (collectively with Aggregate Information, “Non-Personal
          Information”). We use and share this type of data so that we and our Business Partners can understand
          how and how often people use our and their services or websites, which facilitates improving our
          Services, their services and how our Services interface with their services. In addition, these Business
          Partners may share with us non-private, aggregated or otherwise non Personal Information about you that
          they have independently developed or acquired.
        </p>

        <p className="legal-copy">
          Email and SMS Communications with Us:
          As part of the Services, you may occasionally receive push notifications, email, SMS text and other
          communications from us, such as communications relating to your Account.
        </p>

        <p className="legal-copy">
          User Profile Information:
          User profile information including your username and other information you enter may be displayed to
          other users to facilitate user interaction within the Services. We will not directly reveal user email
          addresses to other users.
        </p>
        <p className="legal-copy">
          Social Media Account Login Credentials:
          If you share your Third Party Services account login credentials with us, we may use such information to
          post on your behalf.  We will never share or disclose your login credentials outside of our company.  We
          may share your User Content (as defined in the Terms of Service), or some derivative thereof, on our
          company-branded social media channels, and we may tag you in such post using your name, social media
          name, handle or other identifier.
        </p>
        <p className="legal-copy">
          Information Shared with Our Agents:
          We employ and contract with people and other entities that perform certain tasks on our behalf and who
          are under our control (our “Agents”). We may need to share Personal Information with our Agents in order
          to provide products or services to you. Unless we tell you differently, our Agents do not have any right
          to use Personal Information or other information we share with them beyond what is necessary to assist
          us. You hereby consent to our sharing of Personal Information with our Agents.
        </p>
        <p className="legal-copy">
          Information Disclosed Pursuant to Business Transfers:
          In some cases, we may choose to buy or sell assets. In these types of transactions, user information is
          typically one of the transferred business assets. Moreover, if we, or substantially all of our assets,
          were acquired, or if we go out of business or enter bankruptcy, user information would be one of the
          assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur,
          and that any acquirer of us or our assets may continue to use your Personal Information as set forth in
          this policy.
        </p>
        <p className="legal-copy">
          Information Disclosed for Our Protection and the Protection of Others:
          We also reserve the right to access, read, preserve, and disclose any information as we reasonably
          believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental
          request; (ii) enforce this Privacy Policy and our Terms of Service, including investigation of potential
          violations hereof; (iii) detect, prevent, or otherwise address fraud, security or technical issues; (iv)
          respond to user support requests or (v) protect our rights, property or safety, our users and the
          public. This includes exchanging information with other companies and organizations for fraud protection
          and spam/malware prevention.
        </p>
        <p className="legal-copy">
          Information We Share With Your Consent:
          Except as set forth above, you will be notified when your Personal Information may be shared with third
          parties, and will be able to prevent the sharing of this information.
          Is Information About Me Secure?
        </p>
        <p className="legal-copy">
          We seek to protect Account information to ensure that it is kept private; however, we
          cannot guarantee
          the security of any Account information. We store all of our information, including your IP address and
          device information, using industry-standard techniques. We do not guarantee or warrant that such
          techniques will prevent unauthorized access to information about you that we store, Personal Information
          or otherwise.  Unauthorized entry or use, hardware or software failure, and other factors, may
          compromise the security of user information at any time.  Additionally, you are responsible for
          preventing unauthorized access to your Account and Personal Information by protecting your Account
          credentials and limiting access to Service Device (as defined in the Terms of Service).
        </p>
        <p className="legal-copy">
          What Information of Mine Can I Access?
          If you are a registered user, you can access information associated with your Account by contacting us
          at nathan@taptaprevolution.com. Registered and unregistered users can access and delete cookies through
          their web browser settings.
        </p>
        <p className="legal-copy">
          California Privacy Rights: Under California Civil Code sections 1798.83-1798.84,
          California residents
          are entitled to ask us for a notice identifying the categories of personal customer information which we
          share with our affiliates and/or third parties for marketing purposes, and providing contact information
          for such affiliates and/or third parties. If you are a California resident and would like a copy of this
          notice, please submit a written request to the following address: Marvly LLC 1776 Broadway Unit 1705,
          Denver CO, 80205.
        </p>

        <p className="legal-copy">
          How Can I Delete My Account?
          Should you ever decide to delete your Account, you may do so by emailing taylor@taptaprevolution.com. If
          you terminate your Account, any association between your Account and information we store will no longer
          be accessible through your Account. However, any public activity on your Account prior to deletion will
          remain stored on our servers and will remain accessible to the public.
        </p>

        <p className="legal-copy">
          What Choices Do I Have Regarding My Information?

          You can use some of the features of the Services without registering, thereby limiting the type of
          information that we collect.
          You can always opt not to disclose certain information to us, but please note that certain information
          may be needed to take advantage of some of our features.
          You can delete your Account. Please note that we will need to verify that you have the authority to
          delete the Account, and activity generated prior to deletion will remain stored by us and may be
          publicly accessible.
          You can choose to opt out of certain communications or communication methods; however, if you do so, you
          may not be able to take advantage of some of our Services’ best features.
          What Happens When There Are Changes to this Privacy Policy?
          We may amend this Privacy Policy from time to time. Use of information we collect now is subject to the
          Privacy Policy in effect at the time such information is collected. If we make changes in the way we
          collect or use information, we will notify you by posting an announcement on the Services or sending you
          an email. A user is bound by any changes to the Privacy Policy when he or she uses the Services after
          such changes have been first posted.
          What If I Have Questions or Concerns?
          If you have any questions or concerns regarding privacy using the Services, please send us a detailed
          message nathan@taptaprevolution.com. We will make every effort to resolve your concerns.
          Effective Date: Feb 6, 2020
        </p>
      </div>
    </main>
  </StyledContainer>
);
