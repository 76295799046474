import React from 'react'
import styled from 'styled-components'

import { globalColors } from '@ttr/tokens/colors'

import { AnimatedContainer, PageContainer, Nav } from '../../components'
import { Copy } from '../../components/copy/copy-new'

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 125px 50px 50px;
  flex-direction: column;

  @media (max-width: 720px) {
    padding: 95px 25px 10px;
  }
`

const ContentArea = styled.div`
  background: #fff;
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  max-width: 85%;
  border-radius: 10px;
  overflow-x: hidden;

  @media (max-width: 720px) {
    max-width: 100%;
    padding: 30px;
  }
`

const StyledSection = styled.div``

const StyledH2 = styled.h2`
  font-family: Poppins;
  font-size: 50px;
  margin-top: 0;
  color: #000;
  text-transform: Uppercase;
  line-height: 50px;
  text-align: left;

  &.alt {
    color: #333;
  }

  &.center {
    text-align: center;
  }

  @media (max-width: 720px) {
    line-height: 45px;
    font-size: 35px;
  }
`

const StyledText = styled.p`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  color: #000;
  text-align: left;

  &.bold {
    font-weight: 500;
  }

  @media (max-width: 720px) {
    line-height: 32px;
  }
`

const BoldText = styled.span`
  font-weight: 700;
  margin-right: 5px;
`

export const TermsAndConditions = () => (
  <PageContainer>
    <Nav />
    <ContentContainer>
      <ContentArea>
        <StyledSection>
          <Copy background={globalColors.ttrWhite} className="left">
            <StyledSection>
              <StyledH2>TapTapRev | Terms of Use</StyledH2>
            </StyledSection>
            <StyledSection>
              <StyledText>
                Welcome to the TapTapRev! We hope you will enjoy being part of
                the TapTapRev community by participating in our discord server
                and online mini games and season tournaments we may hold from
                time to time.   IMPORTANT! PLEASE CAREFULLY READ THESE TERMS OF
                USE BEFORE USING TAPTAPREV, MINTING TAPTAPREV TOKENS, ENTERING
                ANY TAPTAPREV SEASON GAME OR MINI GAME, AS THEY DIRECTLY IMPACT
                YOUR LEGAL RIGHTS AND OBLIGATIONS.
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                These Terms and Conditions of Use ("Terms") govern each user's
                ("you," "your," or "user") access to and use of the
                TapTapRev.com Site and the services and features we offer on or
                in connection with it, including any mobile applications
                ("Site"). The Site is provided by Marvly LLC. dba TapTapRev, a
                Delaware Corporation with its business address at 8 The Green,
                Suite B in the City of Dover. Zip code 19901 (collectively
                referred to herein as “TapTapRev," "we," "us," or "our").
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                TapTapRev is pleased to offer you access to our Website and the
                ability to participate in our online non-fungible token-gated
                contests of skill, occasional minting of non-fungible tokens,
                other content, products, services, and promotions (collectively
                the "Services") that we may provide from our Site, subject to
                these Terms, our privacy policy ("Privacy Policy") and the
                Official Rules and Regulations for the applicable contests and
                promotions (the "Rules" or "Rules and Scoring," and together
                with the Terms of Use and the Privacy Policy, the "Agreements").
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                You agree to these Terms by accessing and using our Site,
                registering for Services offered on the Website, or by
                accepting, uploading, staking, submitting, or downloading any
                information or content from or to the Website. These Terms
                constitute a legal agreement between you and TapTapRev and shall
                apply to your use of the Site and Services even after
                termination.  IF YOU DO NOT AGREE TO BE BOUND BY ALL OF THESE
                TERMS, DO NOT USE THE WEBSITE.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1 .Arbitration.</BoldText>
                TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, ANY CLAIM,
                DISPUTE OR CONTROVERSY OF WHATEVER NATURE (“CLAIM”) ARISING OUT
                OF OR RELATING TO THESE TERMS AND/OR SERVICES MUST BE RESOLVED
                BY FINAL AND BINDING ARBITRATION IN ACCORDANCE WITH THE PROCESS
                DESCRIBED IN SECTION 15 BELOW. PLEASE READ SECTION 15 CAREFULLY.
                TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, YOU ARE
                GIVING UP THE RIGHT TO LITIGATE (OR PARTICIPATE IN AS A PARTY OR
                CLASS MEMBER) ALL DISPUTES IN COURT BEFORE A JUDGE OR JURY.
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>1. Eligibility.</BoldText>
                You must be at least eighteen (18) years of age, at the official
                start date of the season or mini game, to participate in
                contests or win prizes offered by TapTapRev. In jurisdictions,
                territories, and locations where the minimum age for permissible
                use of the Website is greater than eighteen (18) years old, you
                must meet the age requirement in your local jurisdiction or
                territory.  You agree to abide by all applicable laws in the
                jurisdiction where you are located while using the
                Site.  TapTapRev employees ("Employees") and Immediate Family
                Members (an "Immediate Family Member" means any domestic partner
                and relative of the employee who resident at an employee's
                residence, including but not limited to parents, grandparents,
                in-laws, children, siblings, and spouses) are not permitted to
                play in any of our games.
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>State and Local Law Compliance.</BoldText>
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Prohibited US States/Countries.</BoldText>
                You acknowledge that various rules, regulations and laws
                addressing sweepstakes, contests, and tournaments with entry
                fees and/or prizes govern your participation in Competitions
                (“Gaming Laws”), and that Gaming Laws are set up by each
                individual US state, country, territory, or jurisdiction. It is
                your responsibility to determine whether the state, country,
                territory or jurisdiction in which you are located is a
                Prohibited Jurisdiction. Together with our developer partners,
                we reserve the right (but have no obligation) to monitor the
                location from which you access Services, and on behalf of our
                developer partners, we may block access from any Prohibited
                Jurisdiction. Each time you log in to participate in a Cash
                Competition, you must accurately confirm the location from which
                you are playing.
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>2. Additional Laws.</BoldText>
                In addition to Gaming Laws, you are also subject to all
                municipal, state and federal laws, rules and regulations of the
                city, state and country in which you reside and from which you
                access and use Services, including without limitation U.S.
                export laws (together with Gaming Laws, the “Applicable Laws”).
                You are solely responsible for your compliance with all
                Applicable Laws. Access to Competitions may not be legal for
                some or all residents of, or persons present in, certain
                jurisdictions. SERVICES AND COMPETITIONS ARE VOID WHERE
                PROHIBITED OR RESTRICTED BY APPLICABLE LAWS. Your participation
                in Competitions is at your own risk, and you agree not to hold
                us responsible or liable if Applicable Laws restrict or prohibit
                your access or participation.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>3. LEGAL DISCLAIMERS.</BoldText>
                WE MAKE NO REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, AS
                TO THE LAWFULNESS OF YOUR PARTICIPATING IN ANY COMPETITION
                OFFERED BY OUR DEVELOPER PARTNERS OR USE OF SERVICES, NOR SHALL
                ANY PERSON AFFILIATED, OR CLAIMING AFFILIATION, WITH US HAVE
                AUTHORITY TO MAKE ANY SUCH REPRESENTATIONS OR WARRANTIES.
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>1. Prize Qualification. </BoldText>
                Winners are posted on the Site leaderboard at the end of the
                game. If you win any prize valued at greater than or equal to
                six hundred United States Dollars ($600.00) at the conclusion of
                the game, you agree to provide individual know your customer
                (“KYC”) documentation, and/or affidavits of eligibility,
                privately to TapTapRev to qualify to receive your prize.  KYC
                varies by global jurisdiction, but TapTapRev will require
                sufficient photo and address identification that is verifiable
                by our trusted third-party identity verification service
                provider.  Should we not be able to verify your identity within
                seven (7) days at the end of the game, you forfeit your prize,
                and the leaderboard will be adjusted accordingly.  
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>1. Participation.</BoldText>
                In addition to Eligibility requirements in Section 1, you
                unconditionally agree to abide by the following requirements,
                rules and decision of TapTapRev:
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>1. Non-Fungible Tokens.</BoldText>
                To participate in a game on our Site, you must hold and insert
                the designated Non-Fungible Token (“NFT”) for that game.  From
                time to time, we will announce to you on our Site, and in the
                TapTapRev Discord community, which tokens qualify for a game
                (“TapTapRev NFT ” or “TapTapRev NFT s”) and the way(s) they can
                be purchased.  You may also purchase our NFTs from third-party
                marketplaces such as OpenSea.  When purchasing NFTs from
                third-party marketplaces, you are subject to their separate
                terms and conditions which are unaffiliated with TapTapRev. NFTs
                for TapTapRev are ERC-721 tokens uniquely identifiable on the
                Ethereum Blockchain.  We will make available on our Site the
                respective Ethereum contract address within which the token
                identification numbers can be found.  IT IS YOUR RESPONSIBILITY
                to make sure you acquire an NFT ONLY from our listed TapTapRev
                contract addresses.  TAPTAPREV WILL NOT BE LIABLE TO YOU FOR
                TOKENS AQUIRED FROM OTHER CONTRACT ADDRESSES, FRAUDULENT SITES,
                OR FAILED TRANSACTIONS FOR PURCHASES MADE ON THIRD-PARTY SITES.
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>2. Digital Wallets.</BoldText>
                Minting or Purchasing an NFT will require you to have a digital
                wallet such as MetaMask to which the NFT will be transferred via
                the Ethereum Blockchain upon purchase.  Participation in our
                games will also require you to connect a digital wallet which
                holds the respective TapTapRev NFT to our Site.  To participate
                in of our games, YOU WILL ONLY BE REQUIRED TO CONNECT YOUR
                WALLET TO THIS SITE.  TapTapRev will never request you to
                connect your digital wallet to any other Sites.  
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>1. Digital Wallet Risks.</BoldText>
                Ownership and any risk associated with any NFT purchase
                transfers to you upon purchase of the NFT.  You are responsible
                for ensuring your Digital Wallet is accurately linked and
                secured to any third-party sites. Wallets such as MetaMask are
                provided by unaffiliated third parties, and your use of such
                Wallets are governed by the terms provided by the applicable
                third party. You understand and acknowledge that your ownership
                of NFTs remains contingent upon you remaining in control of the
                seed phrases and private key(s) associated with your Digital
                Wallets and that we will not store any information in connection
                with your Digital Wallet beyond that required for the sale of
                NFTs.  If you lose your private key, login, or seed phrase for
                your Digital Wallet you will likely permanently lose access to
                your NFTs stored via your Digital Wallet.  TapTapRev CANNOT
                RECOVER ANY PRIVATE KEY OR SEED PHRASE FOR YOUR DIGITAL
                WALLET.    
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>3. Refunds.</BoldText>
                All payments are final. No refunds will be issued.  
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>4. One Active TapTapRev NFT Per Wallet.</BoldText>
                To promote fairness in our games pursuant to our Rules of
                Conduct below, you may only insert one TapTapRev NFT per digital
                wallet.  Any attempts identified by TapTapRev to circumnavigate
                this procedure may result in deactivation of your TapTapRev NFT
                at our sole discretion.  Multiple TapTapRev NFT s may be
                purchased by a single Digital Wallet but must be allocated to
                different wallets for entry in the game.          
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>5. Discord Identification Verification.</BoldText>
                To establishing the leaderboard for our Site, you must register
                and provide a valid and verified Discord Identification to our
                Site prior to the start of each game.  You are responsible for
                maintaining the confidentiality of your Discord Login,
                password(s), and you accept the responsibility for all
                activities, charges, and damages that occur under your account.
                . You are responsible for ensuring you use strong and secure
                passwords, and you may be required to change your passwords from
                time to time. If you have reason to believe that someone is
                using your account without your permission, you should contact
                us immediately. We will not be responsible for any loss or
                damage resulting from your failure to notify us of unauthorized
                use. If we request registration information from you, you must
                provide us with accurate and complete information and must
                update the information when it changes.     
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Contest of Skill.</BoldText>    Contests offered on
                the Site are contests of skill. Winners are determined by the
                objective criteria described in the Contest deadline, Rules,
                scoring, and any other applicable documentation associated with
                the Contest. From all entries received for each Contest, winners
                are determined by the individuals who use their skill and
                knowledge to accumulate the most points according to the
                corresponding scoring rules. The Site and Contests may not be
                used for any form of illicit gambling.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1.  Live Scoring.</BoldText>
                To the extent that we offer 'live' statistics during gameplay,
                all 'live' statistics and other information provided through the
                Site and related information sources are unofficial until the
                end of each round when each participant has
                played.  Leaderboards will only be updated once each individual
                portion of a game is concluded.  Leaderboards will also not be
                accessible during the time within which a round is actively
                being played.  During any live event, neither the hosts nor
                contests will receive any information about leaderboard
                placements from TapTapRev .  Score totals accrued prior to the
                final round will be available to see during the corresponding
                final event for each contest.  
              </StyledText>
            </StyledSection>
            <StyledSection>
              <StyledText>
                <BoldText>1. Contest Results.</BoldText>
                Contest results and prize calculations are based on the final
                statistics and scoring results at the completion of the last
                round within each game. Once Contest results are reviewed and
                graded, prizes are awarded and sent by TapTapRev to the
                Qualified Winners’ Digital Wallets containing the same TapTapRev
                NFT used for participation in the game. The scoring results of a
                Contest will not be changed, except in TapTapRev 's sole
                discretion. 
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Prize Tables.</BoldText>
                Contest prize payouts will be published with the creation of
                each new contest. TapTapRev reserves the right, in its sole
                discretion, to cancel or suspend the contests (or any portion
                thereof) should virus, bugs, unauthorized human intervention, or
                other causes corrupt administration, security, fairness,
                integrity or proper operation of the contest (or any portion
                thereof) warrant doing so. Notification of such changes may be
                provided by TapTapRev to its customers but will not be required.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>Acceptable Use Policy & Disqualification.</BoldText>
                TapTapRev , at its sole discretion and without fault, reserves
                the right to refuse to award benefits or prizes, suspend,
                terminate, or disqualify you and your accounts for any of the
                following reasons:
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                1. It is discovered that you acquired your NFT through
                fraudulent activity such as theft, deception, compromised
                digital wallet, or any other means unauthorized by us at our
                sole discretion.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                2. Continued disrespectful actions or malicious activity
                including, but not limited to prejudice, harassment, threats,
                teaming, or other unsportsmanlike conduct towards other
                participants or members of the TapTapRev Community. 
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                3. Utilizing any information, the Company deems to be improper,
                unfair, or otherwise adverse to the operation of the Contest or
                is in any way detrimental to other entrants.{' '}
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                4. Abusing the Site in any way.  Any type of attempts to attack
                the integrity of our Site, servers, brute force attacks, hack or
                phish user accounts, impede the game, to cheat or gain an unfair
                advantage. 
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                5. Impersonation of participants, community members, or
                employees of TapTapRev.{' '}
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                6. Using automated means (including but not limited to scripts
                and third-party tools) to interact with the Site in any way.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                7. Using automated means (including but not limited to
                harvesting bots, robots, parser, spiders or screen scrapers) to
                obtain, collect or access any information on the Site or of any
                User for any purpose.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                8. If it is discovered you directly stole a winning TapTapRev
                NFT the prize will be awarded to the next person on the
                leaderboard, subject to our discretion.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                9. ANY ATTEMPT BY AN ENTRANT OR ANY OTHER INDIVIDUAL TO
                DELIBERATELY DAMAGE THE WEBSITE OR UNDERMINE THE LEGITIMATE
                OPERATION OF ANY CONTEST IS A VIOLATION OF CRIMINAL AND/OR CIVIL
                LAWS AND SHOULD SUCH AN ATTEMPT BE MADE, TAPTAPREV RESERVES THE
                RIGHT TO SEEK DAMAGES AND OTHER REMEDIES FROM ANY SUCH PERSON TO
                THE FULLEST EXTENT PERMITTED BY LAW.  IN ADDITION, YOU
                ACKNOWLEDGE THAT FORFEITURE OF A PRIZE DOES NOT PROHIBIT
                TAPTAPREV FROM PURSUING LEGAL ACTION.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Intellectual Property.</BoldText>
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                1. Content on the Site, including without limitation, the text,
                software, scripts, graphics, photos, sounds, music, videos,
                interactive features and the like and the trademarks, service
                marks and logos contained therein (the "Intellectual Property"),
                are owned by or licensed to TapTapRev , subject to copyright and
                other intellectual property rights under United States and
                foreign laws and international conventions. Content on the
                Website is provided to you AS IS for your information and
                personal use only and may not be used, copied, reproduced,
                distributed, transmitted, broadcast, displayed, sold, licensed,
                or otherwise exploited for any other purposes whatsoever without
                the prior written consent of the respective owners. TapTapRev
                reserves all rights not expressly granted in and to the Website
                and the Intellectual Property. You agree to not engage in the
                use, copying, or distribution of any of the Intellectual
                Property other than expressly permitted herein. If you download
                or print a copy of the Intellectual Property for personal use,
                you must retain all copyright and other proprietary notices
                contained therein. You agree not to circumvent, disable, or
                otherwise interfere with security related features of the
                Website or features that prevent or restrict use or copying of
                any Intellectual Property or enforce limitations on use of the
                Website or the Intellectual Property therein.  
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                2. TapTapRev retains ownership of all legal rights, title, and
                interest in the artwork which includes each individual trait,
                layer, the traits combined representing your NFT, and all
                underlying metadata contained therein.  In addition, we retain
                the right to assert all trademark and copyright protections
                afforded to us under United States federal laws against any
                identified infringements in regard to the content published and
                contained on this Site, games created by the TapTapRev s team,
                and any of our public-facing media outlets.   
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                3. TAPTAPREV AND OTHER TRADEMARKS CONTAINED ON THE WEBSITE ARE
                TRADEMARKS OR REGISTERED TRADEMARKS OF PLAYGROUND NETWORK INC.
                IN THE UNITED STATES AND/OR OTHER COUNTRIES. THIRD PARTY
                TRADEMARKS, TRADE NAMES, PRODUCT NAMES AND LOGOS MAY BE THE
                TRADEMARKS OR REGISTERED TRADEMARKS OF THEIR RESPECTIVE OWNERS.
                YOU MAY NOT REMOVE OR ALTER ANY TRADEMARK, TRADE NAMES, PRODUCT
                NAMES, LOGO, COPYRIGHT OR OTHER PROPRIETARY NOTICES, LEGENDS,
                SYMBOLS OR LABELS ON THE SITE.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>Miscellaneous</BoldText>
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. General.</BoldText>       In the absence of a
                material breach of these Terms by us or the gross negligence,
                fraud or willful misconduct by us in the operation of our Site,
                games, or when selling NFTs to you under these Terms, we will
                not be liable to you on account of anything done, omitted or
                suffered by us acting in good faith, including in respect of a
                Force Majeure Event.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>2. Termination of Contest.</BoldText>      We reserve
                the right to terminate any contest without advance notice to
                participants. Owning and inserting a TapTapRev NFT does not
                provide you any right to a prize or payout until the final
                leaderboard is stablished after the last portion of a game is
                concluded per the individual contest rules.  If TapTapRev
                concludes a contest, prior to the final game, for whatever
                reason, it may choose to allocate the respective prize pool at
                its own discretion.    
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>3. NFTs are NOT an Investment.</BoldText>      NFTs
                are not intended for speculative use, are not sold or
                represented to be financial products or an ownership interest in
                the TapTapRev , and nothing we publish is in any way financial
                advice to you or any other person.  NFTs may experience or may
                have extreme price volatility, including being worthless in the
                future.  services to you or any other person.  You are solely
                responsible for any decision to enter a transaction subject to
                these NFT Terms, including the evaluation of all risks related
                to any such transaction.  we are not responsible for any Loss
                caused by your failure to act in accordance with our policies,
                procedures or in accordance with our reasonable directions.  you
                purchase NFTs entirely at your own risk and understanding and we
                have not made any representations or warranties as to the IT
                security or ongoing availability of such NFTs or the art or that
                your access to use your NFTs will be uninterrupted, timely or
                secure always.  We do not and cannot guarantee there will be any
                use for, or any price available for any NFT you purchase from
                us; and we do not represent or guarantee any outcomes, or any
                financial return from your acquisition of any NFT from us, save
                the ability to own and enjoy the NFT.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>4. Human Error and Disruptions.</BoldText>  TapTapRev
                is not responsible for: any incorrect, invalid or inaccurate
                entry information; human errors; postal delays/postage due mail;
                technical malfunctions; failures, including public utility or
                telephone outages; omissions, interruptions, deletions or
                defects of any telephone system or network, computer online
                systems, data, computer equipment, servers, providers, or
                software (including, but not limited to software and operating
                systems that do not permit an entrant to participate in a
                Contest), including without limitation any injury or damage to
                any entrant's or any other person's computer or video equipment
                relating to or resulting from participation in a Contest;
                inability to access the Website, or any web pages that are part
                of or related to the Website; theft, tampering, destruction, or
                unauthorized access to, or alteration of, entries and/or images
                of any kind; data that is processed late or incorrectly or is
                incomplete or lost due to telephone, postal issues, computer or
                electronic malfunction or traffic congestion on telephone lines
                or transmission systems, or the Internet, or any service
                provider's facilities, or any phone site or website or for any
                other reason whatsoever; typographical, printing or other
                errors, or any combination thereof.      
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>5.</BoldText>  Nothing in the Terms of Use shall
                create or confer any rights or other benefits in favor of any
                third parties except as specifically provided herein. By
                participating in any Contest on the Site, you agree to
                indemnify, protect, defend and hold harmless TapTapRev and its
                respective directors, officers, employees, agents and
                representatives, from and against any and all third party
                claims, liabilities, losses, damages, injuries, demands,
                actions, causes of action, suits, proceedings, judgments and
                expenses, including reasonable attorneys' fees, court costs and
                other legal expenses including, without limitation, those costs
                incurred at the trial and appellate levels and in any
                bankruptcy, reorganization, insolvency or other similar
                proceedings, and any other legal expenses (collectively,
                "Claims") arising from or connected with your use of the Site,
                any digital wallet and currency used, and/or your participation
                in any Contest. The Site may contain links to third party
                websites that are not owned or controlled by TapTapRev .
                TapTapRev has no control over, and assumes no responsibility
                for, the content, privacy policies, or practices of any
                third-party websites. In addition, TapTapRev will not and cannot
                censor or edit the content of any third-party site. By using the
                Site, you expressly relieve TapTapRev from any and all liability
                arising from your use of any third-party website. Accordingly,
                we encourage you to be aware when you leave the Site and to read
                the terms and conditions and privacy policy of each other
                website that you visit.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>6. </BoldText>    Nothing in the Terms of Use shall
                create or be deemed to create a partnership, agency, trust
                arrangement, fiduciary relationship or joint venture between you
                and TapTapRev .    
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Taxes.</BoldText>          All taxes associated
                with the receipt of any prize are the sole responsibility of the
                winner. In the event that the awarding of any prizes to winners
                of Contests is challenged by any legal authority, TapTapRev
                reserves the right in its sole discretion to determine whether
                or not to award such prizes.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>Disclaimer of Warranties.</BoldText>       
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                1. THE SITE, INCLUDING, WITHOUT LIMITATION, ALL CONTENT,
                SOFTWARE, AND FUNCTIONS MADE AVAILABLE ON OR ACCESSED THROUGH OR
                SENT FROM THE WEBSITE, ARE PROVIDED "AS IS," "AS AVAILABLE," AND
                "WITH ALL FAULTS." TO THE FULLEST EXTENT PERMISSIBLE BY LAW, THE
                COMPANY AND ITS PARENTS, SUBSIDIARIES AND AFFILIATES MAKE NO
                REPRESENTATION OR WARRANTIES OR ENDORSEMENTS OF ANY KIND
                WHATSOEVER (EXPRESS OR IMPLIED) ABOUT: (A) THE WEBSITE; (B) THE
                CONTENT AND SOFTWARE ON AND PROVIDED THROUGH THE WEBSITE; (C)
                THE FUNCTIONS MADE ACCESSIBLE ON OR ACCESSED THROUGH THE
                WEBSITE; (D) THE MESSAGES AND INFORMATION SENT FROM THE WEBSITE
                BY USERS; (E) ANY PRODUCTS OR SERVICES OFFERED VIA THE WEBSITE
                OR HYPERTEXT LINKS TO THIRD PARTIES; AND/OR (F) SECURITY
                ASSOCIATED WITH THE TRANSMISSION OF SENSITIVE INFORMATION
                THROUGH THE WEBSITE OR ANY LINKED SITE. THE COMPANY DOES NOT
                WARRANT THAT THE WEBSITE, ANY OF THE WEBSITES' FUNCTIONS OR ANY
                CONTENT CONTAINED THEREIN WILL BE UNINTERRUPTED OR ERROR-FREE;
                THAT DEFECTS WILL BE CORRECTED; OR THAT THE WEBSITES OR THE
                SERVERS THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER
                HARMFUL COMPONENTS.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                2. TAPTAPREV DOES NOT WARRANT THAT YOUR ACTIVITIES OR USE OF THE
                SITE IS LAWFUL IN ANY PARTICULAR JURISDICTION AND, IN ANY EVENT,
                THE COMPANY SPECIFICALLY DISCLAIMS SUCH WARRANTIES. YOU
                UNDERSTAND THAT BY USING ANY OF THE FEATURES OF THE SITE, YOU
                ACT AT YOUR OWN RISK, AND YOU REPRESENT AND WARRANT THAT YOUR
                ACTIVITIES ARE LAWFUL IN EVERY JURISDICTION WHERE YOU ACCESS OR
                USE THE WEBSITE OR THE CONTENT. FURTHER, THE COMPANY AND ITS
                PARENTS, SUBSIDIARIES AND AFFILIATES DISCLAIM ANY EXPRESS OR
                IMPLIED WARRANTIES INCLUDING, WITHOUT LIMITATION,
                NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
                PURPOSE, AND TITLE.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                3. TAPTAPREV , ITS AFFILIATES, AND THE DIRECTORS, OFFICERS,
                EMPLOYEES, AND OTHER REPRESENTATIVES OF EACH OF THEM, SHALL NOT
                BE LIABLE FOR THE USE OF THE SITE INCLUDING, WITHOUT LIMITATION,
                THE CONTENT AND ANY ERRORS CONTAINED THEREIN. SOME JURISDICTIONS
                LIMIT OR DO NOT ALLOW THE DISCLAIMER OF IMPLIED OR OTHER
                WARRANTIES SO THE ABOVE DISCLAIMER MAY NOT APPLY TO THE EXTENT
                SUCH JURISDICTION'S LAW IS APPLICABLE TO THIS AGREEMENT.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>Limitations on Liability of TapTapRev. </BoldText>   
                   
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                1. YOU UNDERSTAND AND AGREE THAT TAPTAPREV LIMITS ITS LIABILITY
                IN CONNECTION WITH YOUR USE OF THE SITE AS SET FORTH BELOW:
                UNDER NO CIRCUMSTANCES SHALL TAPTAPREV , ITS AFFILIATES, OR THE
                DIRECTORS, OFFICERS, EMPLOYEES, OR OTHER REPRESENTATIVES OF EACH
                OF THEM (COLLECTIVELY, THE "COMPANY ENTITIES AND INDIVIDUALS"),
                BE LIABLE TO YOU FOR ANY LOSS OR DAMAGES OF ANY KIND (INCLUDING,
                WITHOUT LIMITATION, FOR ANY SPECIAL, DIRECT, INDIRECT,
                INCIDENTAL, EXEMPLARY, ECONOMIC, PUNITIVE, OR CONSEQUENTIAL
                DAMAGES) THAT ARE DIRECTLY OR INDIRECTLY RELATED TO (1) THE
                SITE, THE CONTENT, OR YOUR UPLOAD INFORMATION; (2) THE USE OF,
                INABILITY TO USE, OR PERFORMANCE OF THE SITE; (3) ANY ACTION
                TAKEN IN CONNECTION WITH AN INVESTIGATION BY THE COMPANY OR LAW
                ENFORCEMENT AUTHORITIES REGARDING YOUR USE OF THE WEBSITE OR
                CONTENT;(4) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT
                OWNERS; OR (5) ANY ERRORS OR OMISSIONS IN THE WEBSITE'S
                TECHNICAL OPERATION, EVEN IF FORESEEABLE OR EVEN IF THE COMPANY
                ENTITIES AND INDIVIDUALS HAVE BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE,
                STRICT LIABILITY TORT (INCLUDING, WITHOUT LIMITATION, WHETHER
                CAUSED IN WHOLE OR IN PART BY NEGLIGENCE, ACTS OF GOD,
                TELECOMMUNICATIONS FAILURE, OR THEFT OR DESTRUCTION OF THE
                WEBSITE). IN NO EVENT WILL THE COMPANY ENTITIES AND INDIVIDUALS
                BE LIABLE TO YOU OR ANYONE ELSE FOR LOSS OR INJURY, INCLUDING,
                WITHOUT LIMITATION, DEATH OR PERSONAL INJURY. SOME STATES DO NOT
                ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL
                DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO
                YOU. IN NO EVENT SHALL TAPTAPREV AND INDIVIDUALS TOTAL LIABILITY
                TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION EXCEED ONE
                HUNDRED DOLLARS ($100). THE COMPANY ENTITIES AND INDIVIDUALS ARE
                NOT RESPONSIBLE FOR ANY DAMAGE TO ANY USER'S COMPUTER, HARDWARE,
                COMPUTER SOFTWARE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING,
                WITHOUT LIMITATION, DAMAGE FROM ANY SECURITY BREACH OR FROM ANY
                VIRUS, BUGS, TAMPERING, FRAUD, ERROR, OMISSION, INTERRUPTION,
                DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER LINE OR
                NETWORK FAILURE OR ANY OTHER TECHNICAL OR OTHER MALFUNCTION.
                YOUR ACCESS TO AND USE OF THIS SITE IS AT YOUR RISK. IF YOU ARE
                DISSATISFIED WITH THE WEBSITE OR ANY OF THE CONTENT, YOUR SOLE
                AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING THE
                SITE OR THE CONTENT. YOU RECOGNIZE AND CONFIRM THAT IN THE EVENT
                YOU INCUR ANY DAMAGES, LOSSES OR INJURIES THAT ARISE OUT OF THE
                COMPANY'S ACTS OR OMISSIONS, THE DAMAGES, IF ANY, CAUSED TO YOU
                ARE NOT IRREPARABLE OR SUFFICIENT TO ENTITLE YOU TO AN
                INJUNCTION PREVENTING ANY EXPLOITATION OF ANY SITE OR OTHER
                PROPERTY OWNED OR CONTROLLED BY TAPTAPREV OR YOUR UPLOAD
                INFORMATION, AND YOU WILL HAVE NO RIGHTS TO ENJOIN OR RESTRAIN
                THE DEVELOPMENT, PRODUCTION, DISTRIBUTION, ADVERTISING,
                EXHIBITION OR EXPLOITATION OF ANY COMPANY SITE OR OTHER PROPERTY
                OR YOUR UPLOAD INFORMATION OR ANY AND ALL ACTIVITIES OR ACTIONS
                RELATED THERETO. BY ACCESSING THE WEBSITE, YOU UNDERSTAND THAT
                YOU MAY BE WAIVING RIGHTS WITH RESPECT TO CLAIMS THAT ARE AT
                THIS TIME UNKNOWN OR UNSUSPECTED. ACCORDINGLY, YOU AGREE TO
                WAIVE THE BENEFIT OF ANY LAW, INCLUDING, TO THE EXTENT
                APPLICABLE, CALIFORNIA CIVIL CODE SECTION 1542, THAT OTHERWISE
                MIGHT LIMIT YOUR WAIVER OF SUCH CLAIMS.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>
                  1. Arbitration, Disputes, and Jurisdiction {' '}
                </BoldText>
                       
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>
                  1. Consent to Jurisdiction in New York, NY.{' '}
                </BoldText>
                The exclusive jurisdiction and venue for proceedings involving
                any and all disputes, claims or controversies arising out of or
                relating to this Agreement, the breach thereof, or any use of
                the Site (including all commercial transactions) ("Claims")
                shall be the courts of competent jurisdiction sitting within New
                York County, NY (the "Forum"), and the parties hereby waive any
                argument that any such court does not have personal jurisdiction
                or that the Forum is not appropriate or convenient; (b) you
                waive any and all rights to trial by jury with respect to any
                Claims.  If either party initiates a proceeding involving a
                Claim under this Section other than in the Forum, the other
                party shall recover all attorneys' fees and expenses reasonably
                incurred in enforcing this Agreement and the Forum to which the
                parties have herein agreed.      
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>2. General Disputes.</BoldText>      Should you
                encounter an issue with our service, the rulings of which are
                not set forth in these Terms or Rules of Contest, you may submit
                a Dispute via ticket in the TapTapRev Discord server.  
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>3. Arbitration. </BoldText>       If You and We were
                not able to resolve the dispute via informal conversation, You
                agree to resolve all claims before a neutral arbitrator.    
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>4. Class Action Waiver.</BoldText>  To the maximum
                extent permitted under applicable law, any proceedings to
                resolve or litigate any Dispute in any forum will be conducted
                solely on an individual basis. Neither you nor we will seek to
                have any Dispute heard as a class action or in any other
                proceeding in which either party acts or proposes to act in a
                representative capacity. No arbitration or proceeding will be
                combined with another without the prior written consent of all
                parties to all affected arbitrations or proceedings. If this
                waiver is found to be illegal or unenforceable as to all or some
                parts of a Dispute, then it won’t apply to those parts. Instead,
                those parts will be severed and proceed in a court of law, with
                the remaining parts proceeding in arbitration.      
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>5. Arbitration procedure.</BoldText>  If you are
                located within the United States, Canada, the United Kingdom or
                the European Union, or any of their territories, then any
                arbitration will be conducted by the American Arbitration
                Association (the “AAA”) under its Commercial Arbitration Rules.
                You and we each agree to commence arbitration only in New York,
                NY USA. You may request a telephonic or in-person hearing by
                following the AAA rules. In a Dispute involving $10,000 or less,
                any hearing will be telephonic unless the arbitrator finds good
                cause to hold an in-person hearing instead. If you are located
                in a country other than listed above, then arbitration will be
                conducted by the International Court of Arbitration of the
                International Chamber of Commerce (ICC) pursuant to UNCITRAL
                rules, and the arbitration shall be conducted in English and the
                English version of these Terms (and not any translation) shall
                control, and both parties hereby agree to accord this
                arbitration agreement the broadest scope admissible under
                applicable Laws, and that it shall be interpreted in a
                non-restrictive manner. The arbitrator may award the same
                damages to you individually as a court could. The arbitrator may
                award declaratory or injunctive relief only to you individually,
                and only to the extent required to satisfy your individual
                claim. These Terms govern to the extent they conflict with the
                arbitrators’ commercial rules. The arbitrator may award
                compensatory damages but shall NOT be authorized to award
                non-economic damages, such as for emotional distress, or pain
                and suffering or punitive or indirect, incidental or
                consequential damages. Each party shall bear its own attorneys’
                fees, cost and disbursements arising out of the arbitration, and
                shall pay an equal share of the fees and costs of the arbitrator
                and AAA; however, the arbitrator may award to the prevailing
                party reimbursement of its reasonable attorneys’ fees and costs
                (including, for example, expert witness fees and travel
                expenses), and/or the fees and costs of the arbitrator. Within
                fifteen (15) calendar days after conclusion of the arbitration,
                the arbitrator shall issue a written award and a written
                statement of decision describing the material factual findings
                and conclusions on which the award is based, including the
                calculation of any damages awarded. Judgment on the award may be
                entered by any court of competent jurisdiction. The parties
                waive their right to commence any action or judicial proceeding
                in connection with a dispute hereunder, except for purposes of:
                (i) recognition and/or enforcement of the arbitration award or
                any other decision by the arbitral tribunal, (ii) obliging the
                other party to participate in the arbitration proceedings, (iii)
                requesting any type of conservative or interim measure in
                connection with the dispute prior to the constitution of the
                arbitral tribunal, (iv) requesting the appearance of witnesses
                and/or experts, and/or (v) requesting that any information
                and/or documentation discovery be complied with. By agreeing to
                this binding arbitration provision, you understand that you are
                waiving certain rights and protections which may otherwise be
                available if a claim or Dispute were determined by litigation in
                court, including, without limitation, the right to seek or
                obtain certain types of damages precluded by this arbitration
                provision, the right to a jury trial, certain rights of appeal,
                the right bring a claim as a class member in any purported class
                or representative proceeding, and the right to invoke formal
                rules of procedure and evidence.      
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>6. Term to File.</BoldText>      Must be filed within
                one year. To the extent permitted by applicable law, any claim
                or Dispute under these Terms must be filed within one year from
                the date of the cause of action. If a claim or dispute isn’t
                filed within one year, it’s permanently barred.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>7. Equitable Relief.</BoldText>      You agree that we
                would be irreparably damaged if these Terms were not
                specifically enforced. Therefore, in addition to any other
                remedy we may have at law, and notwithstanding our agreement to
                arbitrate Disputes, we are entitled without bond, other
                security, or proof of damages, to seek appropriate equitable
                remedies with respect to your violation of these Terms in any
                court of competent jurisdiction.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>8. Language of the Terms:</BoldText>      If we
                provide a translated version of these Terms, the TapTapRev
                Privacy Policy, or any other terms or policy, it is for
                informational purposes only. If the translated version means
                something different than the English version, then the English
                meaning will be the one that applies.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Termination of Agreement.</BoldText>       
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                1. TapTapRev reserves the right to modify or terminate these
                Terms at any point in the future.  Termination of these Terms
                does not affect the rights of the parties which have accrued
                prior to termination.  Upon termination of these Terms, the
                rights under the License will cease, including the rights to
                use, copy, display, and enjoy the Art associated with the NFT
                you Own, save that any physical objects you have created showing
                the art for your personal use shall be deemed to have a
                continuing personal license to use.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                2. TapTapRev reserves the right to amend these Terms of Use at
                any time and without notice, and it is your responsibility to
                review these Terms of Use for any changes. If you continue to
                use the Services after we change the Terms of Use, you accept
                all changes. The failure of TapTapRev to comply with any
                provision of these Terms of Use due to an act of God, hurricane,
                war, fire, riot, earthquake, terrorism, act of public enemies,
                actions of governmental authorities outside of the control of
                the Company (excepting compliance with applicable codes and
                regulations) or other force majeure event will not be considered
                a breach of these Terms of Use.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Waiver.</BoldText>       No waiver of any term of
                these Terms of Use shall be deemed a further or continuing
                waiver of such term or any other term, and TapTapRev 's failure
                to assert any right or provision under these Terms of Use shall
                not constitute a waiver of such right or provision.
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Contact.</BoldText> help@taptaprev.com
              </StyledText>
            </StyledSection>

            <StyledSection>
              <StyledText>
                <BoldText>1. Entire Agreement.</BoldText>        Entire
                Agreement. This Agreement, including our privacy policy posted
                on the Site, constitutes the entire agreement between you and us
                relating to the subject matter hereof and supersedes all prior
                or contemporaneous written or oral agreements between you and
                us.
              </StyledText>
            </StyledSection>
          </Copy>
        </StyledSection>
      </ContentArea>
    </ContentContainer>
  </PageContainer>
)
