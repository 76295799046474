import React from 'react';
import {
  Link,
} from 'react-router-dom';
import styled from 'styled-components';
import logo from '../../assets/img/logo.svg';

const StyledContainer = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  padding: 0 40px;
`;

export const ContestRules = () => (
  <StyledContainer>
    <nav>
      <div className="logo-wrapper">
        <Link to="/"><img className="logo-nav" src={logo} alt="TapTapRev Logo" /></Link>
      </div>
      <div className="nav-title-wrapper">
        <h1>Terms of Use</h1>
      </div>
    </nav>

    <main stlye={{ padding: '40px 0' }}>
      <div className="legal-container">
        <h2 className="legal-header">TapTapRev CONTEST OFFICIAL RULES</h2>
        <p className="legal-copy">
          NO PURCHASE OR PAYMENT NECESSARY TO ENTER OR WIN. The TapTapRev Application
          (the "Contest") is sponsored by Marvly LLC ("Sponsor"). The Contest is not sponsored, endorsed,
          administered by, or associated with Apple Inc. or its subsidiaries or affiliates.
          THE CONTEST IS VOID WHERE PROHIBITED OR RESTRICTED BY LAW OR WHERE BONDING, REGISTRATION, OR OTHER
          REQUIREMENTS WOULD BE REQUIRED BUT HAVE NOT BEEN MET, OR WHERE THE METHODS OF ENTRY SET FORTH BELOW
          WOULD BE DEEMED CONSIDERATION. ALL APPLICABLE FEDERAL, STATE AND LOCAL LAWS APPLY.
          ELIGIBILITY:
        </p>
        <p className="legal-copy">
          Subject to these Official Rules, the Contest is open to individual persons who are at
          least 18 years old as of the date of entry. To be eligible to receive a Prize (as defined below), the
          information associated with the entrant’s account must be true and accurate. The information associated
          with the entrant’s account must identify the actual entrant into the Contest. In order to facilitate the
          delivery of a Prize (as defined below), TapTapRev may attempt to contact the winner using the
          phone number provided during account registration. If TapTapRev cannot get in contact with the
          winner using that phone number, the winner may forfeit his/her Prize. Certain Contests are only open to
          residents of specific countries. Any person that enters into a Contest that he/she is not eligible to
          enter may not win a Prize (as defined below) in that Contest.
        </p>

        <p className="legal-copy">
          TapTapRev reserves the right to verify an entrant’s eligibility before, during, or after the
          Contest.
        </p>

        <p className="legal-copy">
          Notice to all international entrants: As stated above, it is your responsibility to comply with the
          contest laws of your country/jurisdiction. You acknowledge that certain countries/jurisdictions have
          laws regarding Contests that may prevent Sponsor from awarding you your Prize (as defined below). If you
          are a winner and this happens to be the case with your country/jurisdiction, you acknowledge that you
          may not be able to receive any Prize at all from Sponsor. By entering this Contest, you acknowledge the
          risks, and understand that you may win, but not be able to receive a Prize.
        </p>

        <p className="legal-copy">ENTRY PERIOD:</p>
        <p className="legal-copy">
          Sponsor will announce Contests through the App from time to time. The announcement will indicate that a
          Contest is starting. Contests include live contests of skill speed and accuracy. You may join a Contest
          while it is live. Sponsor reserves the right to cancel Contests, in our sole discretion, with no
          restrictions.
        </p>
        <p className="legal-copy">HOW TO ENTER:</p>
        <p className="legal-copy">
          No purchase or payment is necessary to participate in a Contest. Enter a Contest by completing each of
          the following steps:
        </p>

        <p className="legal-copy">
          Download or open the TapTapRev Application downloaded through the Apple Store on your mobile
          device, and either (a) if you already have a TapTapRev account, log into your existing TapTapRev account, or (b) if you do not already have a TapTapRev account, sign up for a TapTapRev account with a verified phone number.
        </p>

        <p className="legal-copy">
          Compete the sign up process from above and TapTapRev will then automatically crate your account
          if TapTapRev deems you eligible to play. You will receive a notification when the live contest
          begins. A contest will last 5 minutes and eligible players can enter the contest at any time within
          those five minutes. In a contest you are racing to tap the correctly colored targets as quickly as
          possible. The user who has the fastest time in the race wins that contest.
          Play at least one TapTapRev competition to completion.
        </p>

        <p className="legal-copy">
          By downloading TapTapRev, you agree that any information provided by you or collected by
          Sponsor in connection with the Contest may be used by Sponsor in accordance with TapTapRev’s
          Privacy Policy located at https://taptaprev.com/legal/privacy-policy.html and TapTapRev's Terms of Use located at https://taptaprev.com/legal/terms-conditions.html, and may
          be shared with Sponsor’s affiliated business entities. All information submitted to the TapTapRev Application becomes the property of Sponsor.
        </p>

        <p className="legal-copy">
          By downloading TapTapRev, you further agree that use of TapTapRev is subject to TapTapRev’s Terms of Use located at https://taptaprev.com/legal/terms-conditions.html and
          Privacy Policy located at https://taptaprev.com/legal/privacy-policy.html.
        </p>

        <p className="legal-copy">EXTRA LIFE</p>
        <p className="legal-copy">
          An entrant participating in a Contest who has earned, been awarded, or purchased an extra life (“Extra
          Life”) may use that Extra Life to reenter a Contest after their first free try. By default, an entrant’s
          Extra Life will be used upon retrying a competition after the first free try has been completed in each
          competition. Neither Extra Lives nor any other purchase or payment are required to participate in the
          Contest.
        </p>

        <p className="legal-copy">PRIZES:</p>
        <p className="legal-copy">
          The winner of each Contest, upon completion of all verifications and obligation requirements described
          in these Official Rules, will receive a cash prize, determined in Sponsor’s sole discretion (the
          "Prize"). Sponsor will distribute prizes according to the rules for the specific Contest entered and
          players will split Prizes according to those rules (as explained below). You may view the total of all
          accrued Prizes you have won through your TapTapRev account. You will need a minimum Prize
          balance of $5 in your account to cash-out. To receive payment of your accrued Prizes (one minimum amount
          is met), you must cash-out Prizes through CahsApp, a third party service. Prize fulfillment will be
          subject to the terms, conditions, and privacy policies of CashApp, located at www.cash.app. In certain
          instances involving larger Prizes, at TapTapRev's sole discretion, you will be contacted
          directly to receive it by a mutually agreeable method and payment will be subject to a confirmation of
          the winner's eligibility to receive a Prize consistent with Sponsor's Rules and Terms of Use.
          You must comply with the Rules of Conduct contained in section 5 of TapTapRev Terms of Use. Any
          entry by any user that does not comply with the Rules of Conduct, the Terms of Use, or these Contest
          Rules will be disqualified.
        </p>

        <p className="legal-copy">
          Each TapTapRev user may establish, maintain, use and control only one account. Each account may
          only be owned, maintained, used and controlled by one individual. For avoidance of doubt, users may not
          "co-own" accounts. In the event Sponsor determines that you have opened, maintained, used or controlled
          more than one account, in addition to any other rights that Sponsor may have, Sponsor reserves the right
          to suspend or terminate any or all of your accounts and terminate, withhold or revoke the awarding of
          any Prizes.
        </p>

        <p className="legal-copy">
          Each TapTapRev account can only be associated with one CashApp account. And a CashApp account
          cannot be associated with more than one TapTapRev account. Attempts to associate a TapTapRev account with additional CashApp accounts may result in the termination of that TapTapRev account and forfeiture of any Prizes awarded.
        </p>

        <p className="legal-copy">
          TapTapRev will only issue a Prize and any accompanying tax forms to the individual identified
          in the account information for the winning account. TapTapRev uses your account information to
          contact and verify winners. Out of date or incorrect account information at the time you enter a Contest
          may disqualify you from receiving a Prize. Failure to provide valid, working contact information in
          connection with your account may result in termination of that TapTapRev account and forfeiture
          of any Prizes awarded.
        </p>

        <p className="legal-copy">
          Sponsor expressly reserves the right to disqualify any entries that it believes in good faith are
          generated by an automated means or scripts or that violate any of these Contest Rules or the Terms of
          Use. Entries generated by script, macro or other automated means are void.
        </p>

        <p className="legal-copy">
          Decisions of the Sponsor are final and binding with respect to all matters related to the Contest. In no
          event shall the Sponsor be obligated to award any Prizes other than the Prize specified in these
          Official Rules. The Sponsor does not guarantee that the Prize can be made available to the winner. The
          Prize is not assignable and not transferable and no substitutions are permitted. In addition, if the
          Prize is unable to be fulfilled to the winner due to any applicable law, rule or regulation, or if the
          winner cannot claim the Prize for any reason, then Sponsor reserves the right to not award the Prize at
          all. The winner shall be responsible and liable for all federal, state and local taxes on the value of
          the Prize. Winners shall provide TapTapRev with accurate information regarding their identity
          and country of residence. To receive a complete list of winners or a copy of the Official Rules, send a
          self-addressed stamped envelope to Marvly LLC 1776 Broadway Unit 1705, Denver CO, 80205.
          {' '}
        </p>

        <p className="legal-copy">PRIZE SELECTION:</p>
        <p className="legal-copy">
          The winner will be notified in the TapTapRev Application. The winner must cash-out the Prize
          within 90 days after notification. A Contest winner's failure to cash-out the Prize within the specified
          90 days will be considered that Contest winner's forfeiture of the Prize and may not award the Player.
        </p>

        <p className="legal-copy">TapTapRev</p>

        <p className="legal-copy">
          In a TapTapRev contest, Players must select the correctly colored targets on the screen. If a
          player selects an incorrect colored target a time penalty will be added to their score using an
          exponential decay equation. The winner of a TapTapRev Contest will be the person with the
          fastest time after the 5 minute competition is expired.
        </p>

        <p className="legal-copy">GENERAL CONDITIONS AND RELEASES:</p>

        <p className="legal-copy">
          Notice to all international entrants: As stated above, it is your responsibility to comply with the
          contest laws of your country/jurisdiction. You acknowledge that certain countries/jurisdictions have
          laws regarding Contests that may prevent Sponsor from awarding you your Prize (as defined below). If you
          are a winner and this happens to be the case with your country/jurisdiction, you acknowledge that you
          may not be able to receive any Prize at all from Sponsor. By entering this Contest, you acknowledge the
          risks, and understand that you may win but not be able to receive a Prize.
          {' '}
        </p>

        <p className="legal-copy">
          Notice to all entrants (including international): By entering the Contest or accepting a Prize, you
          agree to conform to all federal, state and local laws and regulations. When applicable, the winner may
          be required to execute and return (and winning may be conditioned upon the winner executing and
          returning) to Sponsor, within ten (10) business days, an Affidavit of Eligibility and a Liability and
          Publicity Release drafted by Sponsor to be eligible for the Prize. The winner may be required in
          Sponsor’s sole discretion to complete relevant tax forms as a condition to the delivery of the
          applicable Prize. Winner may also be required to furnish proof of identity, postal address, and birth
          date in order to receive a Prize.
        </p>

        <p className="legal-copy">
          Unless prohibited by applicable law, your acceptance of a Prize constitutes your permission to use your
          name, submissions, photograph, likeness, voice, address (city and state) and testimonials in all media,
          in perpetuity, in any manner Sponsor deems appropriate for publicity purposes without any compensation
          to such entrant or any review or approval rights, notifications, or permissions; and constitutes your
          consent to disclose your personally identifiable information to third parties (including, without
          limitation, placing the winner’s name on a winner’s list). Notwithstanding any rights of publicity,
          privacy or otherwise (whether or not statutory) anywhere in the world, your acceptance of a Prize
          constitutes your authorization to (i) have Sponsor (and its agents, consultants and employees)
          photograph, record, tape, film and otherwise visually and audiovisually record you, (ii) have Sponsor
          (and its agents, consultants and employees) use, reproduce, disseminate, alter, edit, dub, modify,
          distort, add to, subtract from, process and otherwise exploit any results of such activity (including
          without limitation any manner in which such activity may be recorded or remembered or modified) or
          derivatives or extensions or imitations thereof in any manner that Sponsor sees fit, in any medium or
          technology known or hereinafter invented, throughout the universe in perpetuity, including without
          limitation for illustration, art, promotion, advertising, trade or any other purpose whatsoever; and
          (iii) have relinquished any right that you may have to examine or approve the completed product or
          products or the advertising copy or printed matter that may be used in conjunction therewith or the use
          to which it may be applied.
        </p>

        <p className="legal-copy">
          In order to be eligible for a Prize above $1,000 (a “Large Prize”), Sponsor may require a winner to,
          within ten (10) business days of winning the relevant Contest, (i) sign a Liability and Publicity
          Release as drafted by Sponsor, which may impose obligations beyond those stated in these Official Rules,
          and (ii) partake in an interview conducted by Sponsor or its agent, providing truthful and accurate
          information in response to the interview questions, and (iii) be photographed, recorded, taped, filmed,
          or otherwise visually or audiovisually recorded while partaking in such an interview or in other
          circumstances to be determined in Sponsor’s sole discretion. A winner’s refusal to fulfill either any of
          these obligations shall cause the winner to be ineligible for the Large Prize and Sponsor may withhold
          or revoke the awarding of the Large Prize. Sponsor, in its sole discretion, shall reasonably determine
          the subject, timing, and means of interviewing the winner.
        </p>

        <p className="legal-copy">
          An entrant or winner may be disqualified from the Contest if he or she fails to comply or has previously
          failed to comply with any provision of these Official Rules or the Sponsor’s Terms of Use, as determined
          in the sole discretion of the Sponsor. Sponsor, in its sole discretion, may disqualify you from a
          Contest, refuse to award Prizes and require the return of any Prizes, or suspend, limit, or terminate
          your account if you engage in conduct Sponsor deems, in its sole discretion, to be improper, unfair,
          fraudulent or otherwise adverse to the operation of Contests or in any way detrimental to other users.
          Improper conduct includes, but is not limited to: Falsifying personal information, including payment
          information, required to claim a Prize; violating eligible payment method terms, including the terms of
          any cash rewards payment card, violating any of these Rules, using unauthorized methods such as
          unauthorized scripts or other automated means; tampering with the administration of the Contest or
          trying to in any way tamper with the computer programs associated with the Contests; obtaining other
          entrants’ information and spamming other entrants; and any other form of abuse; or otherwise violating
          these Rules. You acknowledge that the forfeiture and/or return of any Prize shall in no way prevent
          Sponsor from informing the relevant authorities, and/or pursuing criminal or civil proceedings in
          connection with such conduct.
        </p>

        <p className="legal-copy">
          Participation in the Contest is at entrant’s own risk. Sponsor shall not be liable for: (1) failed,
          returned or misdirected notifications based on inaccurate information provided by the winner in
          connection with an entry; (2) entries and responses to winner notifications which are lost, late,
          incomplete, illegible, unintelligible, postage-due, misdirected, damaged or otherwise not received by
          the intended recipient in whole or in part or for computer or technical error of any kind; (3) any
          electronic miscommunications or failures, technical hardware or software failures of any kind, lost or
          unavailable network connections, or failed incomplete, garbled or delayed computer transmissions which
          may limit an entrant's ability to participate in the Contest; (4) any technical malfunctions of the
          telephone network, computer on-line system, computer equipment, software, program malfunctions or other
          failures, delayed computer transactions or network connections that are human, mechanical or technical
          in nature, or any combination thereof, including any injury or damage to entrant's or any other person's
          computer or mobile device related to or resulting from downloading the App or otherwise in connection
          with this Contest; or (5) any warranty with respect to any Prize or any component thereof.
        </p>

        <p className="legal-copy">
          THE CONTEST IS PROVIDED "AS IS" AND SPONSOR DOES NOT MAKE ANY, AND HEREBY DISCLAIMS ANY AND ALL,
          REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING THE CONTEST.
          NOTWITHSTANDING ANYTHING ELSE HEREIN OR OTHERWISE, SPONSOR SHALL NOT BE LIABLE OR OBLIGATED WITH RESPECT
          TO ANY SUBJECT MATTER OF THIS AGREEMENT OR UNDER CONTRACT, NEGLIGENCE, STRICT LIABILITY OR OTHER LEGAL
          OR EQUITABLE THEORY FOR (A) ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES (INCLUDING,
          WITHOUT LIMITATION, LOSS OF REVENUE, GOODWILL, OR ANTICIPATED PROFITS) (B) AMOUNTS IN EXCESS OF THE
          PRIZE FOR THE APPLICABLE CONTEST, (C) DATA LOSS OR COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES,
          AND/OR (D) ANY MATTER BEYOND SUCH PARTIES’ REASONABLE CONTROL.
        </p>

        <p className="legal-copy">
          By entering the Contest, you agree to and hereby do release and hold harmless Sponsor, its respective
          parents, subsidiaries and affiliated entities, directors, officers, employees, attorneys, agents, and
          representatives (the "Released Parties") from any damage, injury, death, loss, claim, action, demand, or
          other liability (collectively, “Claims”) that may arise from your acceptance, possession and/or use of
          any Prize or your participation in this Contest, or from any misuse or malfunction of any Prize awarded,
          regardless of whether such Claims, or knowledge of the facts constituting such Claims, exist at the time
          of entry or arise at any time thereafter, and indemnify each of the Released Parties from any damages
          arising therefrom. Any person attempting to defraud or in any way tamper with this Contest may be
          prosecuted to the full extent of the law. Sponsor reserves the right to modify these Official Rules in
          any way or at any time. Sponsor reserves the right, in its sole discretion, to cancel or suspend this
          Contest should viruses, bugs or other causes beyond its control corrupt the administration, security or
          proper operation of the Contest. In the event of cancellation or suspension, TapTapRev shall
          promptly post a notice on the TapTapRev App to such effect. This Contest shall be governed by
          New York law, without regard to conflicts of laws provisions. By participating in this Contest, you
          agree that any dispute or litigation arising from or relating to this Contest shall be determined by
          binding arbitration only in Denver, Colorado by and under the Commercial Arbitration Rules for
          arbitration of consumer-related disputes (which can be accessed at www.jamsadr.com or by calling
          1.800.352.5267)s, and judgment on the award rendered by the arbitrator(s) may be entered in any court
          having jurisdiction thereof. Notwithstanding the foregoing, Sponsor may seek equitable relief in any
          court of competent jurisdiction. If any provision of these Rules is held to be illegal or unenforceable,
          such provision shall be limited or eliminated to the minimum extent necessary so that these Rules
          otherwise remain in full force and effect and enforceable.
        </p>

      </div>
    </main>
  </StyledContainer>
);
