import React from 'react';
import { Transition } from 'react-transition-group';
import styled from 'styled-components';
import logo from '../../assets/img/logo2.svg';

const StyledLogo = styled.img`
  z-index: 3;
  position: relative;
  width: 175px;

  @media(max-width: 720px) {
    margin-bottom: 20px;
    width: 100px;
  }
`;

export const Logo = ({ className, show }) => {
  const duration = 1000;

  const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    zIndex: 3,
  };

  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
  };

  return (
    <Transition in={show} timeout={duration}>
      {(state) => (
        <div className={className} style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
        >
          <StyledLogo src={logo} alt="TapTapRev Logo" />
        </div>
      )}
    </Transition>
  );
};
