import React from 'react';
import styled from 'styled-components';
import { globalColors } from '@ttr/tokens/colors';
import { AnimatedContainer, PageContainer, Nav } from '../components';
import { useWindowSize } from '../hooks/use-window-size';

const RoadmapContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 50px 0;
  flex-direction: column;

  @media (max-width: 1080px) {
    justify-content: flex-end;
  }

  @media (max-width: 720px) {
    padding: 0 25px 0;
    height: 95vh;
  }
`;

const Phases = styled.div`
  width: 100%;
  flex-direction: row;
  display: flex;
  padding: 40px 0 0;
  height: 70%;
  justify-content: center;

  @media (max-width: 1080px) {
    flex-wrap: wrap;
    overflow: auto;
    height: 90%;
    padding: 40px 0px 0;
  }

  @media (max-width: 720px) {
    margin-top: 60px;
    height: 100%;
    padding: 100px 0px 0;
  }
`;

const Phase = styled(AnimatedContainer)`
  border: 2px solid ${globalColors.ttrDarkBlue};
  background: rgba(69,159,248, .85);
  width: 25%;
  margin: 0 10px;
  height: 100%;
  max-height: 370px;
  justify-content: center;
  position: relative;
  border-radius: 10px;

  &:nth-of-type(even) {
    align-self: flex-end;
  }

  &:before {
    color: ${globalColors.ttrGold};
    content: attr(data-phase);
    background: ${globalColors.ttrDarkBlue};
    display: flex;
    position: absolute;
    top: -24px;
    left: 0;
    padding: 0 30px 0 20px;
    clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
  }

  &.active:before {
    color: ${globalColors.ttrWhite};
  }

  &:after {
    content: '';
    clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
    background: rbga(0, 0, 0, .2);
    position: absolute;
  }

  @media (max-width: 1080px) {
    width: 46%;
    margin-bottom: 50px;
  }

  @media (max-width: 720px) {
    width: 100%;
    margin-bottom: 50px;
    max-height: 100%;
    height: 65%;
  }
`;

const PhaseTitle = styled.h2`
  color: ${globalColors.ttrWhite};
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 700;
  letter-spacing: -1px;
  text-transform: uppercase;
  border-bottom: 2px solid ${globalColors.ttrDarkBlue};
  padding: 0 20px 20px;
`;

const StyledOl = styled.ul`
  margin-right: 0;
  padding: 0;
  padding-left: 30px;
  margin-bottom: 10px;
`;

const PhaseInfo = styled.li`
  text-align: left;
  color: ${globalColors.ttrWhite};
  margin: 0;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 17px;
  padding: 0 18px 0 10px;

  span {
    color: ${globalColors.ttrDarkBlue};
  }
`;

export const Roadmap = () => {
  const { width } = useWindowSize();

  return (
    <PageContainer>
      <Nav />
      <RoadmapContainer>
        <Phases>
          <Phase phase="01" maxOpacity={0.5} initial={{ opacity: 0 }} delay={width < 720 ? 0 : 0.3}>
            <PhaseTitle>Running</PhaseTitle>
            <StyledOl>
              <PhaseInfo>Build Discord</PhaseInfo>
              <PhaseInfo>Grow community</PhaseInfo>
              <PhaseInfo>Audit & Test contract</PhaseInfo>
              <PhaseInfo>Finalize evolving NFT artwork</PhaseInfo>
            </StyledOl>
          </Phase>

          <Phase className="active" phase="02" initial={{ opacity: 0 }} delay={width < 720 ? 0 : 0.5}>
            <PhaseTitle className="active">Climbing</PhaseTitle>
            <StyledOl>
              <PhaseInfo>Launch on IOS</PhaseInfo>
              <PhaseInfo>OG Mint & WL Mint</PhaseInfo>
              <PhaseInfo>Public Mint</PhaseInfo>
              <PhaseInfo>OG private game 20ETH prize</PhaseInfo>
              <PhaseInfo>WL private game 15ETH prize</PhaseInfo>
            </StyledOl>
          </Phase>

          <Phase phase="03" initial={{ opacity: 0 }} delay={width < 720 ? 0 : 0.7}>
            <PhaseTitle>Tapping</PhaseTitle>
            <StyledOl>
              <PhaseInfo>Weekly 12ETH prize games</PhaseInfo>
              <PhaseInfo>BI-Weekly holder giveways</PhaseInfo>
              <PhaseInfo>Launch on all Major OS</PhaseInfo>
            </StyledOl>
          </Phase>

          <Phase phase="04" initial={{ opacity: 0 }} delay={width < 720 ? 0 : 1}>
            <PhaseTitle>Summiting</PhaseTitle>
            <StyledOl>
              <PhaseInfo>Release two new games</PhaseInfo>
              <PhaseInfo>Master Access Card to all future games</PhaseInfo>
              <PhaseInfo>Award ceremony at SXSW | TBA</PhaseInfo>
              <PhaseInfo>Never ending giveways</PhaseInfo>
            </StyledOl>
          </Phase>
        </Phases>
      </RoadmapContainer>
    </PageContainer>
  );
};
