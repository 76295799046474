import React from 'react'
import styled from 'styled-components'
import { globalColors } from '@ttr/tokens/colors'
import { Transition } from 'react-transition-group'
import { Link } from 'react-router-dom'

const StyledFooter = styled.footer`
  background: transparent;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  z-index: 10;
  width: 100%;
  left: 0;
  bottom: 0;

  @media (max-width: 960px) {
    display: none;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    height: 100px;
  }
`

const StyledLink = styled(Link)`
  font-family: Poppins;
  font-weight: 300;
  color: ${globalColors.ttrWhite};
  text-decoration: none;
  font-size: 12px;

  &:hover {
    color: ${globalColors.ttrGreen};
  }
`

const StyledCopyRight = styled.span`
  font-family: Poppins;
  font-weight: 300;
  color: ${globalColors.ttrWhite};
  font-size: 12px;
  opacity: .75;
`

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 30px;
  width: 50%;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  width: 50%;
`;

export const Footer = ({ show }) => {
  return (
    <StyledFooter>
      <Left>
        <StyledCopyRight>© 2022 Marvly, LLC.</StyledCopyRight>
      </Left>
      <Right>
        <StyledLink to="/legal/terms-conditions">Terms & Conditions</StyledLink>
      </Right>
      {/* <StyledLink to="/legal/contest-rules">Contest Rules</StyledLink>
          <StyledLink to="/legal/privacy-policy">Privacy Policy</StyledLink> */}

    </StyledFooter>
  )
}
