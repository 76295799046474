import React from 'react';
import styled from 'styled-components';

import { globalColors } from '@ttr/tokens/colors';

import { Footer } from '../footer/footer';

import background from '../../assets/img/ttt-dot-com-bg3.svg';
import backgroundMobile from '../../assets/img/ttr-dot-com-bg-mobile-min.jpg';

const StyledMain = styled.div`
  background-color: ${globalColors.ttrGreen};
  background-image: url(${background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  height: 100vh;
  width: 100%;
  z-index: 2;

  @media (max-width: 720px) {
    background-image: url(${backgroundMobile});
    background-size: 100% 100%;
    padding-bottom: 100px;
    justify-content: flex-start;
  }
`;

export const PageContainer = ({ children }) => (
  <StyledMain className="App">
    {children}
    <Footer />
  </StyledMain>
);
