import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import { Close } from 'grommet-icons'

import { globalColors } from '@ttr/tokens/colors'

import { IphoneHome } from '../components'

import { WalletContext } from '../context/wallet-context'
import { AnimatedContainer, PageContainer, Nav, Countdown } from '../components'
import nftArt from '../assets/img/bubble_1.png'
import loadingGif from '../assets/img/TTR_Loading_2.gif'

const MintWrapper = styled(AnimatedContainer)`
  border: 2px solid ${globalColors.ttrDarkBlue};
  background: rgba(69, 159, 248, 0.85);
  width: 30%;
  margin: 0 10px;
  height: 95%;
  // max-height: 525px;
  justify-content: flex-start;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 65px;
  min-width: 500px;

  &:before {
    color: ${globalColors.ttrWhite};
    content: attr(data-phase);
    background: ${globalColors.ttrDarkBlue};
    display: flex;
    position: absolute;
    top: -24px;
    left: 0;
    padding: 0 30px 0 20px;
    clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
  }

  &:after {
    content: '';
    clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
    background: rbga(0, 0, 0, 0.2);
    position: absolute;
  }

  @media (max-width: 1080px) {
    width: 80%;
    margin-left: 0;
  }

  @media (max-width: 720px) {
    width: 90%;
    min-width: 280px;
    height: 85%;
    margin-right: 0;
  }
`

const MintContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0 0;
  justify-content: center;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  @media (max-width: 1080px) {
    flex-wrap: wrap;
    // overflow: auto;
    height: 90%;
    padding: 40px 0px 0;
    max-height: 100%;
  }

  @media (max-width: 720px) {
    // margin-top: 60px;
    height: 100%;
    margin: 0;
    padding: 100px 0px 0;
  }
`

const PhaseTitle = styled.h2`
  color: ${globalColors.ttrWhite};
  font-size: 45px;
  margin-bottom: 20px;
  margin: 0;
  font-weight: 700;
  letter-spacing: -2px;
  text-transform: uppercase;
  border-bottom: 2px solid ${globalColors.ttrDarkBlue};
  padding: 20px 20px 20px;

  @media (max-width: 420px) {
    font-size: 33px;
  }
`

const StyledConnectMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`

const StyledConnectButton = styled.button`
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  height: 60px;
  min-height: 60px;
  border-radius: 75px;
  margin-bottom: 5px;
  // min-width: 335px;
  max-width: 335px;
  width: 100%;
  border: none;
  outline: none;
  background: ${globalColors.ttrDarkBlue};
  border: 2px solid transparent;
  color: ${globalColors.ttrWhite};
  text-transform: uppercase;
  text-align: center;
  font-size: 19px;
  padding: 0 30px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background: transparent;
    border-color: ${globalColors.ttrDarkBlue};
    color: ${globalColors.ttrDarkBlue};
  }
`

const MintContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: 0 40px;
`

const StyleQuantitySelect = styled(Select)`
  margin-bottom: 20px;
  color: white;

  .react-select__control {
    border: none;
    outline: none;
  }

  .react-select__control {
    background: ${globalColors.ttrDarkBlue};
    width: 100%;
    // width: 100%;
  }

  &&& .react-select__indicators {
    svg {
      color: ${globalColors.ttrWhite};
    }
  }

  .react-select__single-value,
  .react-select__option {
    color: white;
    text-align: left;

    font-family: 'Poppins';
  }

  .react-select__single-value {
    color: white;
    text-align: left;
  }

  .react-select__menu {
    background: ${globalColors.ttrDarkBlue};
    margin-top: 0;
    border-radius: 0 0 8px 8px;
  }

  .react-select__control--menu-is-open {
    &:hover {
      svg {
        color: ${globalColors.ttrGreen};
      }
    }
    svg {
      transform: rotate(180deg);
      color: ${globalColors.ttrGreen};
    }
  }

  .react-select__menu-list {
    padding-top: 0;
  }

  .react-select__option--is-focused {
    background: transparent;
  }

  .react-select__option {
    text-align: left;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      color: ${globalColors.ttrGreen};
    }
  }

  .react-select__option--is-selected {
    background: rgba(255, 255, 255, 0.1);
    color: ${globalColors.ttrGreen};
  }
`

const ErrorText = styled.p`
  color: ${globalColors.ttrWhite};
  font-size: 20px;
  margin: 0;
  position: absolute;
  text-transform: Capitalize;

  @media (max-width: 720px) {
    font-size: 16px;
  }
`

const SuccessText = styled.p`
  color: ${globalColors.ttrDarkGreen};
  font-size: 20px;
  margin: 0;
  position: absolute;
  text-transform: Capitalize;
`

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

const MintTitle = styled.h2`
  font-size: 35px;
  color: ${globalColors.ttrWhite};
  margin: 30px 0 60px;
  font-weight: 700;
  text-transform: uppercase;
`

const SelectWrapper = styled.div`
  margin-bottom: 80px;
  width: 100%;

  @media (max-width: 720px) {
    margin-bottom: 60px;
  }
`

const SelectLabel = styled.p`
  color: ${globalColors.ttrWhite};
  text-transform: uppercase;
  margin-bottom: 10px;

  @media (max-width: 720px) {
    font-size: 14px;
  }
`

const ContentWrapper = styled.div`
  height: 625px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`

const Error = styled.div`
  background: ${globalColors.ttrDotRed};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.85;
`

const Success = styled.div`
  background: ${globalColors.ttrGreen};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.95;
`

const StyledIphone = styled(IphoneHome)`
  @media (max-width: 1080px) {
    display: none;
  }
`

const LoadingImage = styled.img`
  height: 50px;
  width: 50px;
  margin: 0;
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const MintDefs = {
  MINT_PRICE: 0.065,
  MINT_MAX: 8,
}

export const PublicMint = () => {
  const { connectWallet, TTR, address, connected, web3js: web3 } = useContext(
    WalletContext,
  )

  const [quantity, setQuantity] = useState()
  const [error, setError] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [countdown, setCountdown] = useState(null)
  const [loading, setLoading] = useState(true)
  const [minting, setMinting] = useState(false)
  const [showCountdown, setShowCountdown] = useState(true)
  const [errorProviderType, setErrorProviderType] = useState('')
  const [mintSuccess, setMintSuccess] = useState(false)
  const [customErrorType, setCustomErrorType] = useState('')
  const toWei = (num) => web3.utils.toWei(num.toString())

  const handleConnect = useCallback(() => {
    // create new session
    if (connectWallet) connectWallet()
  }, [connectWallet])

  const handleQuantityChange = useCallback(
    (value) => {
      setQuantity(value)
    },
    [setQuantity],
  )

  const handleClearError = useCallback(() => {
    setError(null)
  }, [setError])

  const handleCloseSuccess = useCallback(() => {
    setMintSuccess(false)
  }, [setMintSuccess])

  const ProviderTypes = {
    METAMASK: 'metamask',
    WALLETCONNECT: 'walletconnect',
  }

  const handleMint = useCallback(async () => {
    // quantity.value // remeber to add dependancy bellow
    setMinting(true)
    setError(null)
    setMintSuccess(false)
    await TTR.methods
      .publicMint(quantity.value)
      .send({
        from: address,
        value: toWei(MintDefs.MINT_PRICE * parseInt(quantity.value)),
      })
      .on('receipt', (receipt) => {
        setMinting(false)
        setMintSuccess(true)
      })
      .catch((error) => {
        setMinting(false)
        setError(
          error?.receipt?.transactionHash
            ? error?.receipt?.transactionHash
            : `${error?.message.split('{')[0]}`,
        )
        setErrorProviderType(
          error?.receipt?.transactionHash
            ? ProviderTypes.METAMASK
            : ProviderTypes.WALLETCONNECT,
        )
        setCustomErrorType(error?.message.split('{')[0])
      })
  }, [
    setError,
    address,
    TTR,
    setMinting,
    setErrorProviderType,
    setMintSuccess,
    setCustomErrorType,
    quantity
  ])

  const getTimeRemaining = useCallback(() => {
    const t =  parseInt(startTime * 1000) - Math.round((new Date()).getTime())
    const seconds = Math.floor((t / 1000) % 60)
    const minutes = Math.floor((t / 1000 / 60) % 60)
    const hours = Math.floor((t / (1000 * 60 * 60)) % 24)
    const days = Math.floor(t / (1000 * 60 * 60 * 24))

    if (t <= 0 || !t) {
      return {
        total: 0,
        days: '00',
        hours: '00',
        minutes: '00',
        seconds: '00',
      }
    }

    return {
      total: t,
      days: days < 10 ? '0' + days : days,
      hours: hours < 10 ? '0' + hours : hours,
      minutes: minutes < 10 ? '0' + minutes : minutes,
      seconds: seconds < 10 ? '0' + seconds : seconds,
    }
  }, [startTime])

  useEffect(() => {
    const refetchInterval = setInterval(() => {
      setCountdown(getTimeRemaining())
    }, 1000)

    return () => clearInterval(refetchInterval)
  }, [getTimeRemaining, setCountdown])

  useEffect(() => {
    if (!connected && error) {
      setError(null)
    }
  }, [setError, error, connected])

  const buildOptions = (maxMint) =>
    Array(maxMint)
      .fill('')
      .map((_, index) => {
        const num = index + 1
        return {
          value: num,
          label: `${num} - ${Number(
            (MintDefs.MINT_PRICE * num).toFixed(3),
          )} ETH`,
        }
      })

  const options = buildOptions(MintDefs.MINT_MAX)

  useEffect(() => {
    setQuantity(options[0])
  }, [])

  const setMintStartTime = useCallback(async () => {
    setStartTime(await TTR.methods.publicMintStartTime().call())
  }, [TTR])

  useEffect(() => {
    if (TTR && !startTime) {
      setMintStartTime(TTR)
    }
  }, [TTR, setMintStartTime])

  useEffect(() => {
    if (countdown) {
      setLoading(false)
      setShowCountdown(Boolean(parseInt(countdown?.total) > 0))
    }
  }, [setLoading, countdown, setShowCountdown])

  const renderError = useCallback(() => {
    if (errorProviderType === ProviderTypes.METAMASK) {
      return (
        <ErrorText>
          Error:{' '}
          <a
            style={{ color: globalColors.ttrWhite, cursor: 'pointer' }}
            target="_blank"
            href={`https://etherscan.io/tx/${error}`}
          >
            See Why On Etherscan Here
          </a>
        </ErrorText>
      )
    }

    if (errorProviderType === ProviderTypes.WALLETCONNECT) {
      return <ErrorText>{error}</ErrorText>
    }
  }, [customErrorType, errorProviderType, error])

  const renderContent = useCallback(() => {
    switch (!!connected) {
      case true:
        return (
          <MintContent>
            <Right>
              <MintTitle>Let's Mint</MintTitle>
              <SelectWrapper>
                {/* <LinkDown style={{ marginBottom: 20 }} size='medium' color={globalColors.ttrWhite} /> */}
                <SelectLabel>Number of TTR's you want to Mint</SelectLabel>
                <StyleQuantitySelect
                  classNamePrefix="react-select"
                  options={options}
                  value={quantity}
                  onChange={handleQuantityChange}
                />
              </SelectWrapper>
              <StyledConnectButton onClick={handleMint}>
                Mint
              </StyledConnectButton>
            </Right>
          </MintContent>
        )
      default:
        return (
          <MintContent>
            <StyledConnectButton onClick={handleConnect}>
              Connect
            </StyledConnectButton>
          </MintContent>
        )
    }
  }, [connected, quantity, error])

  return (
    <PageContainer>
      <Nav />
      <MintContainer>
        <ContentWrapper>
          <StyledIphone show />
          <MintWrapper phaase="Mint">
            <PhaseTitle>Mint: Season 1</PhaseTitle>
            {showCountdown && (
              <Countdown countdown={countdown} loading={loading} />
            )}
            {!showCountdown && !minting && (
              <StyledConnectMessage>{renderContent()}</StyledConnectMessage>
            )}
            {!showCountdown && minting && (
              <ImageContainer>
                <h2 style={{ color: globalColors.ttrWhite }}>Please Wait...</h2>
                <LoadingImage src={loadingGif} />
              </ImageContainer>
            )}
          </MintWrapper>
        </ContentWrapper>
      </MintContainer>
      {mintSuccess && (
        <Success>
          <SuccessText>Minted!</SuccessText>
          <Close
            onClick={handleCloseSuccess}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              height: 15,
              width: 15,
              cursor: 'pointer'
            }}
            color={globalColors.ttrDarkGreen}
          />
        </Success>
      )}
      {error && (
        <Error>
          {renderError()}
          <Close
            onClick={handleClearError}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              height: 15,
              width: 15,
              cursor: 'pointer'
            }}
            color={globalColors.ttrWhite}
          />
        </Error>
      )}
    </PageContainer>
  )
}
