import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { globalColors } from '@ttr/tokens/colors';
import { Transition } from 'react-transition-group';
import {
  Link,
} from 'react-router-dom';
import loadingGif from '../../assets/img/TTR_Loading_2.gif'

const Container = styled.div`
  flex-direction: column;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;

const NumberContainer = styled.div`
  justify-content: center;
  align-items: center;
  width: 75px;
  border-radius: 4;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const NumbersWrapper = styled.div`
  flex-direction: row;
  display: flex;
`;

const TimerText = styled.p`
  font-family: Poppins;
  font-weight: 500;
  font-size: 38px;
  color: ${globalColors.ttrWhite};
  letter-spacing: 0.72;
  text-align: center;
  line-height: 40px;
  margin: 0;
  padding: 0;
`;

const DateLabel = styled.span`
  font-size: 20px;
  color: ${globalColors.ttrWhite};
  opacity: 0.8,
  padding-right: 20px;
  line-height: 28px;
`;

const StyledTitle = styled.h3`
  font-family: Poppins;
  font-weight: 300;
  font-size: 28px;
  color: ${globalColors.aux2};
  letter-spacing: 3.84;
  text-align: center;
  margin: 0;
  margin-bottom: 10px;
`;

const LoadingImage = styled.img`
  height: 50px;
  width: 50px;
  margin: 0;
`;

export const Countdown = ({ loading, countdown }) => {
  if (loading && !countdown) {
    return (
      <Container>
        <LoadingImage src={loadingGif} />
      </Container>
    );
  }

  return (
    <Container>
    <StyledTitle>THE MINT STARTS IN</StyledTitle>
      <NumbersWrapper>
      <NumberContainer>
        <TimerText>{countdown?.days}</TimerText>
        <DateLabel>d</DateLabel>
      </NumberContainer>
      <NumberContainer>
        <TimerText>{countdown?.hours}</TimerText>
        <DateLabel>h</DateLabel>
      </NumberContainer>
      <NumberContainer>
        <TimerText>{countdown?.minutes}</TimerText>
        <DateLabel>m</DateLabel>
      </NumberContainer>
      <NumberContainer>
        <TimerText>{countdown?.seconds}</TimerText>
        <DateLabel>s</DateLabel>
      </NumberContainer>
      </NumbersWrapper>
    </Container>
  );
};
