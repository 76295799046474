import React from 'react';
import styled from 'styled-components';

import { globalColors } from '@ttr/tokens/colors';

import { AnimatedContainer, PageContainer, Nav } from '../components';
import { Copy } from '../components/copy/copy-new';

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 125px 50px 50px;
  flex-direction: column;

  @media (max-width: 720px) {
    padding: 95px 25px 10px;
  }
`;

const ContentArea = styled.div`
  background: rgba(69, 159, 248, 0.85);
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
  max-width: 85%;
  border-radius: 10px;
  overflow-x: hidden;

  @media (max-width: 720px) {
    max-width: 100%;
    padding: 30px;
  }
`;

const StyledH2 = styled.h2`
  font-family: Poppins;
  font-size: 50px;
  margin-top: 0;
  color: white;
  text-transform: Uppercase;
  line-height: 50px;
  text-align: left;

  &.alt {
    color: #333;
  }

  &.center {
    text-align: center;
  }

  @media (max-width: 720px) {
    line-height: 45px;
    font-size: 35px;
  }
`;

const StyledText = styled.p`
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  color: white;
  text-align: left;

  &.bold {
    font-weight: 500;
  }

  @media (max-width: 720px) {
    line-height: 32px;
  }
`;

const BoldText = styled.span`
  font-weight: 700;
  margin-right: 5px;
`;

export const Manifesto = () => (
  <PageContainer>
    <Nav />
    <ContentContainer>
      <ContentArea>
        <AnimatedContainer initial={{ opacity: 0 }}>
          <Copy background={globalColors.ttrWhite} className="left">
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledH2>What is TTR</StyledH2>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
                TapTapRev is the first tech ready mobile game with massive weekly competitions for Blue Chip NFT prizes.
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
              The name of the game in TapTapRev is to tap Revos (correctly colored bubbles in the game) as fast as you can without ever tapping a Skully (the incorrectly colored bubbles in the game)!
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
              For each TTR NFT you own, you are allocated a certain amount of tries in each weekly competition based on your token Level. (Ie: a level 1 TTR NFT has 3 tries, a level 2 TTR NFT has 6 tries). Every time you play in a TapTapRev competition you are automatically rewarded with XP towards your TTR NFT. If you hold multiple TTR NFTs all of your NFTs will be rewarded XP even if you only use tries from a single NFT (This way you don’t have to redundantly play if you are in first place/ want to level up NFTs for secondary market!). Once a TTR NFT reaches its XP threshold it will automatically level up(without any staking or gas fees!), which means the art will evolve and it will now allow more tries in TapTapRev competitions! After a try In TapTapRev you will see how far behind the current first place player you are and be allowed to give it another shot(as long as you still have tries left) OR that you are in first place and you can hope to hold your position! Games are fast, competitive and only the fastest tappers will surface to the leaderboards!
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
                <BoldText>How is TTR being funded:</BoldText>
                The game will be funded based on our mint. Our first season will be comprised of 52 weeks (or 52 competitions) that will be funded from our mint alone. Any royalties will also be allocated to our Increasing the weekly prizes and will also be used for business development and more weekly raffles for our holders.
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
              <BoldText>Where can I see current giveaways:</BoldText>
                We have a transparency channel in our Discord that you can see all of our past giveaways, when they were sent, and who they were sent to. This will be our structure for all giveaways, movement of funds, etc. for everyone to see anytime. We also have some information on the team in our Discord team doxx channel that will be updated as the team grows and expands.
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
              <BoldText>What can I expect to play for:</BoldText>
                The NFT’s being given away each week will be chosen by the community, with a discord driven voting system for holders only. Each week will have a 7-10 ETH prize and giveaway budget that we will use to purchase a Blue Chip NFT or group of NFT’s to give away to that weeks’ winner(s)! We will also continue running discord giveaways for holders in the same community voted fashion!
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
              <BoldText>What is the mint price:</BoldText>
                All mint information will always be in our Discord, but it is 0.15ETH for OG, and 0.20ETH for WL and 0.25ETH for the public mint. We will announce a date in both Discord and Twitter. We will NOT have a stealth mint, or a secret mint before our official launch date.
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
              <BoldText>What if I have more questions:</BoldText>
                You can always reach out to anyone on the TTR Team (which will not DM you first), open a support ticket in our Discord or you can ask away in our DM’s or here on Twitter! Our team and community is always happy to help with any questions or concerns you may have!
              </StyledText>
            </AnimatedContainer>
            <AnimatedContainer initial={{ opacity: 0 }}>
              <StyledText>
                Welcome to TTR – We cant wait to see you tap!
              </StyledText>
            </AnimatedContainer>
          </Copy>
        </AnimatedContainer>
      </ContentArea>
    </ContentContainer>
  </PageContainer>
);
