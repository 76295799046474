
import React from 'react';
import {
  Link
} from "react-router-dom";
import styled from 'styled-components';
import logo from '../../assets/img/logo.svg';

const StyledContainer = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  padding: 0 40px;
`;

export const TermsAndConditions = () => {
  return (
    <StyledContainer>
      <nav>
        <div class="logo-wrapper">
          <Link to="/"><img class="logo-nav" src={logo} alt="TapTapRev Logo" /></Link>
        </div>
      </nav>

      <main stlye={{ padding: "40px 0" }}>
        <div class="legal-container">
          <h2 class="legal-header">TapTapRev Terms of Use</h2>
          <p class="legal-copy">IMPORTANT NOTICE: THIS AGREEMENT IS SUBJECT TO BINDING ARBITRATION AND A WAIVER OF CLASS ACTION RIGHTS AS
			      DETAILED IN SECTION 13 BELOW</p>

          <p class="legal-copy">Please read these Terms of Use (collectively with the TapTapRev Privacy Policy and the TapTapRev Official Rules, the "Terms of Use") fully and carefully before using the TapTapRev
          application (the “App”) and the services, features, content or applications offered by Marvly, LLC in
          connection with the App (“Company,” “we”, “us” or “our”) (together with the App, the “Services”). These
          Terms of Use set forth the legally binding terms and conditions for your use of the App and the Services.
          Acceptance of Terms of Use. a. By signing up for, installing and/or using the App in any manner, you agree
          to these Terms of Use and all other operating rules, policies and procedures that may be published from time
          to time through the App by us, each of which is incorporated by reference and each of which may be updated
          from time to time without notice to you. b. Certain of the Services may be subject to additional terms and
          conditions specified by us from time to time; your use of such Services is subject to those additional terms
          and conditions, which are incorporated into these Terms of Use by this reference, including without
          limitation the TapTapRev Official Rules. c. These Terms of Use apply to all users of the Services,
          including, without limitation, users who are contributors of content, information, and other materials or
          services, registered or otherwise. d. ARBITRATION NOTICE AND CLASS ACTION WAIVER: EXCEPT FOR CERTAIN TYPES
          OF DISPUTES DESCRIBED IN THE ARBITRATION SECTION BELOW (SECTION 13), YOU AGREE THAT DISPUTES BETWEEN YOU AND
          US WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS
          ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.</p>

          <p class="legal-copy">Eligibility. You represent and warrant that you are an individual person at least 18 years of age. If you
          are under age 18, you may not, under any circumstances or for any reason, use the Services. We may, in our
          sole discretion, refuse to offer the Services to any person or entity and change its eligibility criteria at
          any time. You are solely responsible for ensuring that these Terms of Use are in compliance with all laws,
          rules and regulations applicable to you and the right to access the Services is revoked where these Terms of
          Use or use of the Services is prohibited or to the extent offering, sale or provision of the Services
          conflicts with any applicable law, rule or regulation. Further, the Services are offered only for your use,
          and not for the use or benefit of any third party. You are not eligible for our Contests if you violate any
          provision of these Terms of Use or the TapTapRev Official Rules, as determined in TapTapRev’s sole discretion.</p>

          <p class="legal-copy">Registration. To sign up for the Services, we may require you to register for an Account on the Services (an
          "Account") or log in via Facebook Connect, Twitter or another authentication mechanism (a “Third Party
          Account”). You must provide accurate and complete information and keep your Account information updated. If
          you provide any information that is inaccurate, not current or incomplete, or Company has reasonable grounds
          to suspect that such information is inaccurate, not current or incomplete, Company may deny you access to
          Contests, areas requiring registration, disqualify you from Contests, revoke Prizes, and/or terminate your
          Account, at its sole discretion.</p>

          <p class="legal-copy">You shall not: (i) select or use as a username a name of another person
          with the intent to impersonate that person; (ii) use as a username a name subject to any rights of a person
          other than you without appropriate authorization; or (iii) use, as a username, a name that is otherwise
          offensive, vulgar or obscene. You are solely responsible for the activity that occurs on your Account, and
          for keeping your Account password and login credentials secure. You may never use another person’s user
          Account or registration information for the Services without permission. You must notify us immediately of
          any change in your eligibility to use the Services (including any changes to or revocation of any licenses
          from state authorities), breach of security or unauthorized use of your Account. You should never publish,
          distribute or post login information for your Account. You shall have the ability to delete your Account,
          either directly or through a request made to one of our employees or affiliates.</p>

          <p class="legal-copy">You may establish,
          maintain, use and control only one Account on the Service. Each Account on the Service may only be owned,
          maintained, used and controlled by one individual. For avoidance of doubt, users may not "co-own" accounts
          on the Service. In the event Company determines that you have opened, maintained, used or controlled more
          than one Account, in addition to any other rights that Company may have, Company reserves the right to
          suspend or terminate any or all of your accounts and terminate, withhold or revoke the awarding of any
          Prizes.</p>

          <p class="legal-copy">Each year all winners over the previous year may be required to provide updated address and social
          security (or other tax identification number) details to Company. These details will be used to allow
          Company to comply with tax regulations and may be shared with appropriate tax authorities. You, not Company,
          are responsible for filing and paying applicable state and federal taxes on any winnings. Company does not
          provide tax advice, nor should any statements in this Agreement or on the Service be construed as tax
          advice.</p>

          <p class="legal-copy">Content.</p>

          <p class="legal-copy">a. Definition. For purposes of these Terms of Use, the term "Content" includes, without limitation,
          information, data, text, photographs, videos, audio clips, written posts and comments, software, scripts,
          graphics, and interactive features generated, provided, or otherwise made accessible on or through the
          Services. For the purposes of this Agreement, “Content” also includes all User Content (as defined
          below).</p>

          <p class="legal-copy">b. User Content. All Content added, created, uploaded, submitted, distributed, or posted to the
          Services by users (collectively "User Content"), whether publicly posted or privately transmitted, is the
          sole responsibility of the person who originated such User Content. You represent that all User Content
          provided by you is accurate, complete, up-to-date, and in compliance with all applicable laws, rules and
          regulations. You retain ownership of any and all User Content created and/or uploaded by you. You
          acknowledge that all Content, including User Content, accessed by you using the Services is at your own risk
          and you will be solely responsible for any damage or loss to you or any other party resulting therefrom. We
          do not guarantee that any Content you access on or through the Services is or will continue to be
          accurate.</p>

          <p class="legal-copy">c. Notices and Restrictions. The Services may contain Content specifically provided by us, our
          partners or our users and such Content is protected by copyrights, trademarks, service marks, patents, trade
          secrets or other proprietary rights and laws. You shall abide by and maintain all copyright notices,
          information, and restrictions contained in any Content accessed through the Services.</p>

          <p class="legal-copy">d. Use License.
          Subject to these Terms of Use, we grant each user of the Services a worldwide, non-exclusive,
          non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely
          for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content
          (other than your User Content) for other than purposes of using the Services is expressly prohibited without
          prior written permission from us. You shall not sell, license, rent, or otherwise use or exploit any Content
          (other than your User Content) for commercial use or in any way that violates any third party
          right.</p>

          <p class="legal-copy">e. License Grant. By submitting User Content through the Services, you hereby do and shall grant us a
          worldwide, non-exclusive, perpetual, royalty-free, fully paid, sublicensable and transferable license to
          use, edit, modify, manipulate, truncate, aggregate, reproduce, distribute, prepare derivative works of,
          display, perform, and otherwise fully exploit the User Content in connection with the App, the Services and
          our (and our successors’ and assigns’) businesses, including without limitation for promoting and
          redistributing part or all of the App or the Services (and derivative works thereof) in any media formats
          and through any media channels (including, without limitation, third party websites and feeds), and
          including after your termination of your Account or the Services. For the sake of clarity, to the extent any
          User Content you submit includes your name, likeness, voice, video, or photograph, you acknowledge and agree
          that the foregoing license of this Section 4(e) shall apply to the same. You also hereby do and shall grant
          each user of the App and/or the Services a non-exclusive, perpetual license to access your User Content
          through the App and/or the Services, and to use, edit, modify, reproduce, distribute, prepare derivative
          works of, display and perform such User Content, including after your termination of your Account or the
          Services. For clarity, the foregoing license granted to us and our users does not affect your other
          ownership or license rights in your User Content, including the right to grant additional licenses to your
          User Content, unless otherwise agreed in writing. You represent and warrant that you have all rights to
          grant such licenses to us without infringement or violation of any third party rights, including without
          limitation, any privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other
          intellectual property or proprietary rights.</p>

          <p class="legal-copy">f. Availability of Content. We do not guarantee that any
          Content will be made available on the App or through the Services. We reserve the right to, but do not have
          any obligation to, (i) remove, edit or modify or otherwise manipulate any Content in our sole discretion, at
          any time, without notice to you and for any reason (including, but not limited to, upon receipt of claims or
          allegations from third parties or authorities relating to such Content or if we are concerned that you may
          have violated these Terms of Use), or for no reason at all and (ii) to remove or block any Content from the
          Services.</p>


          <p class="legal-copy">Rules of Conduct.</p>

          <p class="legal-copy">a. As a condition of use, you promise not to use the Services for any purpose that is
          prohibited by these Terms of Use. You are responsible for all of your activity in connection with the
          Services. Violation of our rules may result in the termination and cancellation of your Account and
          forfeiture of your winnings. You acknowledge and agree that we may terminate any TapTapRev Account
          at any time for any reason (including, but not limited to, our independent assessment or the receipt of
          claims or allegations from third parties or authorities).</p>

          <p class="legal-copy">b. By way of example, and not as a limitation, you
          shall not (and shall not permit any third party to) either (a) take any action or (b) upload, download,
          post, submit or otherwise distribute or facilitate distribution of any Content on or through the Service,
          including without limitation any User Content, that:</p>

          <p class="legal-copy">i. infringes any patent, trademark, trade secret,
          copyright, right of publicity or other right of any other person or entity or violates any law or
          contractual duty, including but not limited to, facilitating the rebroadcast of the TapTapRev
          Services (see our DMCA Copyright Policy in Section 14 below);</p>

          <p class="legal-copy">ii. uses the Services for any unauthorized
          purpose, or in violation of any applicable law, including intellectual property laws;</p>

          <p class="legal-copy">iii. uses screen
          reader technology, algorithms, or any other automated technological means to interpret, analyze, research,
          or gain information about a question or submit an answer to a question;</p>

          <p class="legal-copy">iv. you know is false, misleading,
          untruthful or inaccurate, including, but not limited to, providing inaccurate contact or Account
          information;</p>

          <p class="legal-copy">>v. illicitly manufactures Extra Lives or other benefits by providing inaccurate information,
          buying or selling such benefits, creating multiple accounts, or other means; vi. results in the creation or
          operation of multiple user accounts;</p>

          <p class="legal-copy">vii. creates multiple entries into Contests, by any means, including
          multi-accounting;</p>

          <p class="legal-copy">viii. logs an Account into multiple devices simultaneously;</p>

          <p class="legal-copy">ix. logs an Account into more
          than five different devices over the course of the history of the Account;</p>

          <p class="legal-copy">x. logs multiple Accounts into a
          single device over the course of the history of any of the Accounts; xi. enters you into a contest for which
          you are not eligible, whether by multi-accounting, providing misleading information, masking or altering
          your IP address, or other means;</p>

          <p class="legal-copy">xii. results in the sale or transfer of your Account;</p>

          <p class="legal-copy">xiii. is unlawful, threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another's privacy,
          tortious, obscene, vulgar, pornographic, offensive, profane, contains or depicts nudity, contains or depicts
          sexual activity, otherwise violates any law or right of any third party, or is otherwise inappropriate as
          determined by us in our sole discretion; xiv. constitutes unauthorized or unsolicited advertising, junk or
          bulk email ("spamming");</p>

          <p class="legal-copy">xv. contains software viruses or any other computer codes, files, content, or
          programs that are designed or intended to disrupt, damage, limit or interfere with the proper function of
          any software, hardware, or telecommunications equipment or to damage or obtain unauthorized access to any
          system, data, password or other information of ours or of any third party;</p>

          <p class="legal-copy">xvi. is designed or intended to
          obtain password, Account, or private information from any TapTapRev user;</p>

          <p class="legal-copy">xvii. impersonates any
          person or entity, including any of our employees, representatives, or users;</p>

          <p class="legal-copy">xviii. promotes or links to
          affiliate programs, multi-level marketing schemes, sites repurposing existing stories or off-topic content;
          or</p>

          <p class="legal-copy">xix. includes anyone’s identification documents or sensitive financial information.</p>

          <p class="legal-copy">c. You shall not: (i)
          take any action that imposes or may impose (as determined by us in our sole discretion) an unreasonable or
          disproportionately large load on our (or our third party providers’) infrastructure; (ii) interfere or
          attempt to interfere with the proper working of the Services or any activities conducted on the Services;
          (iii) bypass, circumvent or attempt to bypass or circumvent any measures we may use to prevent or restrict
          access to the Services (or other accounts, computer systems or networks connected to the Services); (iv) run
          any form of auto-responder or "spam" on the Services; (v) use manual or automated software, devices, or
          other processes to “crawl” or “spider” any page of the App without our express written permission; (vi)
          harvest or scrape any Content from the Services; or (vii) otherwise take any action in violation of our
          guidelines and policies.</p>

          <p class="legal-copy">d. You shall not (directly or indirectly): (i) decipher, decompile, disassemble,
          reverse engineer or otherwise attempt to derive any source code or underlying ideas or algorithms of any
          part of the Services (including without limitation any application), except to the limited extent applicable
          laws specifically prohibit such restriction, (ii) modify, translate, or otherwise create derivative works of
          any part of the Services, or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights
          that you receive hereunder. You shall abide by all applicable local, state, national and international laws
          and regulations.</p>

          <p class="legal-copy">e. We also reserve the right to access, read, preserve, and disclose any information as we
          reasonably believe is necessary to (i) satisfy any applicable law, regulation, legal process or governmental
          request, (ii) enforce these Terms of Use, including investigation of potential violations hereof, (iii)
          detect, prevent, or otherwise address fraud, security or technical issues, (iv) respond to user support
          requests, or (v) protect the rights, property or safety of us, our users and the public.</p>

          <p class="legal-copy">f. If for any
          reason the Service is not running as originally planned (e.g., if it becomes corrupted or does not allow the
          proper usage and processing of entries in accordance with the rules, or if infection by a computer virus,
          bugs, tampering, unauthorized intervention, actions by entrants, fraud, technical failures, or any other
          causes of any kind, in the sole opinion of Company corrupts or affects the administration, security,
          fairness, integrity or proper conduct of the Service), Company reserves the right, in its sole discretion,
          to disqualify any individual implicated in or relating to the cause and/or to cancel, terminate, extend,
          modify or suspend the Service, and select the winner(s) from all eligible entries. If such cancellation,
          termination, modification or suspension occurs, notification may be posted on the Site.</p>

          <p class="legal-copy">Third-Party Services. The Services may permit you to link to or otherwise access other websites, services or
          resources on your device and the Internet, and other websites, services or resources may contain links to or
          be accessed by the Services or the App (including, without limitation, sites and services to synchronize
          video to music). These other resources are not under our control, and you acknowledge that we are not
          responsible or liable for the content, functions, accuracy, legality, appropriateness or any other aspect of
          such websites or resources. The inclusion of any such link or access does not imply our endorsement or any
          association between us and their operators. You further acknowledge and agree that we shall not be
          responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or
          in connection with the use of or reliance on any such content, goods or services available on or through any
          such website or resource.</p>

          <p class="legal-copy">Location-Based Services. We may offer features that are based on the location of users and which may report
          on the locations of those users as they use the Services (the "Location-Based Services"). You may partake in
          using these Location-Based Services solely at your own discretion, and may opt out of providing such
          information by turning off those features. Should you use Location-Based Services, you are consenting to our
          collection and dissemination of your location information through the Services. Under no circumstances shall
          we be liable for claims or damages therefrom arising out of your informed decision to disseminate your
          location information through the Service.</p>

          <p class="legal-copy">In-App Purchases. Through the Applications, you may purchase ("In App Purchase") certain goods designed to
          enhance the performance of the Services (“Goods”). When you purchase Goods, you are doing so through either
          Apple iTunes service or the Google Play service and you are agreeing to their respective Terms and
          Conditions.
          (http://www.apple.com/legal/internet-services/itunes/us/terms.html; http://play.google.com/intl/en_us/about/play-terms.html). We
          are not a party to any In App Purchase.</p>

          <p class="legal-copy">Termination. We may terminate your access to all or any part of the Services at any time, with or without
          cause, with or without notice, effective immediately, which may result in the forfeiture and destruction of
          all information associated with your use of the Services. If you wish to terminate your Account, you may do
          so by removing the App from your device and following the instructions on the App or through the Services.
          All provisions of these Terms of Use which by their nature should survive termination shall survive
          termination, including, without limitation, licenses of User Content, ownership provisions, warranty
          disclaimers, indemnity and limitations of liability.</p>

          <p class="legal-copy">Warranty Disclaimer.</p>

          <p class="legal-copy">a. We have no special relationship with or fiduciary duty to you. You acknowledge that
          we have no duty to take any action regarding:</p>

          <p class="legal-copy">i. which users gain access to the Services;</p>

          <p class="legal-copy">ii. what Content
          you access via the Services; or</p>

          <p class="legal-copy">iii. how you may interpret or use the Content.</p>

          <p class="legal-copy">b. You release us from all
          liability for you having acquired or not acquired Content through the Services. We make no representations
          concerning any Content contained in or accessed through the Services, and we will not be responsible or
          liable for the accuracy, copyright compliance, or legality of material or Content contained in or accessed
          through the Services.</p>

          <p class="legal-copy">c. The views and opinions of our hosts are their own and not necessarily
          representative of the views and opinions of the Company or its employees, and the Company assumes no
          responsibility for the Content, accuracy, or views of or opinions expressed by such hosts. d. THE SERVICES
          AND CONTENT ARE PROVIDED "AS IS", “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
          INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND
          FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE,
          ALL OF WHICH ARE EXPRESSLY DISCLAIMED. WE, AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND
          CONTENT PROVIDERS DO NOT WARRANT THAT: (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME
          OR LOCATION; (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED; (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR
          THROUGH THE SERVICES IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR (IV) THE RESULTS OF USING THE
          SERVICES WILL MEET YOUR REQUIREMENTS. YOUR USE OF THE SERVICES IS SOLELY AT YOUR OWN RISK.</p>

          <p class="legal-copy">Indemnification. You shall defend, indemnify, and hold harmless us, our affiliates and each of our and their
          respective employees, contractors, directors, suppliers and representatives from all liabilities, claims,
          and expenses, including reasonable attorneys’ fees, that arise from or relate to your use or misuse of, or
          access to, the Services, Content, or otherwise from your User Content, violation of these Terms of Use, or
          infringement by you, or any third party using your Account or identity in the Services, of any intellectual
          property or other right of any person or entity. We reserve the right to assume the exclusive defense and
          control of any matter otherwise subject to indemnification by you, in which event you will assist and
          cooperate with us in asserting any available defenses.</p>

          <p class="legal-copy">Limitation of Liability. IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR
          CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR
          EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF
          SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL
          DAMAGES OF ANY KIND WHATSOEVER (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
          (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE)
          OF THE GREATER OF (A) THE VALUE OF THE PRIZES YOU HAVE WON DURING THE IMMEDIATELY PREVIOUS THREE (3) MONTH
          PERIOD OR (B) $50.00.</p>


          <p class="legal-copy">ARBITRATION CLAUSE & CLASS ACTION WAIVER–IMPORTANT–PLEASE REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS</p>

          <p class="legal-copy">13.1.Binding Arbitration</p>

          <p class="legal-copy">a. Either party may initiate binding arbitration as the sole means to resolve claims,
          subject to the terms set forth below. Specifically, all claims arising out of or relating to these Terms
          (including their formation, performance and breach), the parties’ relationship with each other and/or your
          use of the Service shall be finally settled by binding arbitration administered by the JAMS in accordance
          with the provisions of its Streamlined Arbitration Rules & Procedures, excluding any rules or procedures
          governing or permitting class actions.</p>

          <p class="legal-copy">b. The arbitrator, and not any federal, state or local court or
          agency, shall have exclusive authority to resolve all disputes arising out of or relating to the
          interpretation, applicability, enforceability or formation of these Terms, including, but not limited to any
          claim that all or any part of these Terms are void or voidable, or whether a claim is subject to
          arbitration. The arbitrator shall be empowered to grant whatever relief would be available in a court under
          law or in equity. The arbitrator’s award shall be written, and binding on the parties and may be entered as
          a judgment in any court of competent jurisdiction.</p>

          <p class="legal-copy">c. The Commercial Arbitration Rules governing the
          arbitration may be accessed at www.jamsadr.com or by calling 1.800.352.5267. To the extent the filing fee
          for the arbitration exceeds the cost of filing a lawsuit, Company will pay the additional cost. If the
          arbitrator finds the arbitration to be non-frivolous, Company will pay all of the actual filing and
          arbitrator fees for the arbitration, provided your claim does not exceed $75,000. The arbitration rules also
          permit you to recover attorney’s fees in certain cases. The parties understand that, absent this mandatory
          provision, they would have the right to sue in court and have a jury trial. They further understand that, in
          some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may
          be more limited in arbitration than in court.</p>

          <p class="legal-copy">13.2 Location</p>
          <p class="legal-copy">a. If you are a resident of the United States,
          arbitration will take place at any reasonable location within the United States convenient for you. For
          residents in Canada, arbitration shall be initiated in the County of New York, State of New York, United
          States of America, and you and Company agree to submit to the personal jurisdiction of any federal or state
          court in New York County, New York, in order to compel arbitration, to stay proceedings pending arbitration,
          or to confirm, modify, vacate or enter judgment on the award entered by the arbitrator.</p>

          <p class="legal-copy">13.3 Class Action Waiver</p>
          <p class="legal-copy">a. The parties further agree that any arbitration shall be conducted in their individual capacities
          only and not as a class action or other representative action, and the parties expressly waive their right
          to file a class action or seek relief on a class basis. YOU AND COMPANY AGREE THAT EACH MAY BRING CLAIMS
          AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY
          PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. If any court or arbitrator determines that the class action
          waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can
          proceed on a class basis, then the arbitration provision set forth above shall be deemed null and void in
          its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.</p>

          <p class="legal-copy">13.4 Exception–Litigation of Intellectual Property and Small Claims Court Claims a. Notwithstanding the parties'
          decision to resolve all disputes through arbitration, either party may bring an action in state or federal
          court to protect its intellectual property rights ("intellectual property rights" means patents, copyrights,
          moral rights, trademarks, and trade secrets, but not privacy or publicity rights). Either party may also
          seek relief in a small claims court for disputes or claims within the scope of that court’s
          jurisdiction.</p>

          <p class="legal-copy">13.5 30-Day Right to Opt Out</p>

          <p class="legal-copy">a. You have the right to opt out and not be bound by the
          arbitration and class action waiver provisions set forth above by sending written notice of your decision to
          opt out to the following address: Intermedia Labs, Inc., 138 Spring Street, New York, New York 10012. The
          notice must be sent within 30 days of 04/20/2018 or your first use of the Service, whichever is later,
          otherwise you shall be bound to arbitrate disputes in accordance with the terms of those paragraphs. If you
          opt out of these arbitration provisions, Company also will not be bound by them.</p>

          <p class="legal-copy">13.6 Changes to this Section</p>
          <p class="legal-copy">a. Company will provide 60-days’ notice of any changes to this section. Changes will become
          effective on the 60th day, and will apply prospectively only to any claims arising after the 60th day. b.
          For any dispute not subject to arbitration you and Company agree to submit to the personal and exclusive
          jurisdiction of and venue in the federal and state courts located in New York, NY. You further agree to
          accept service of process by mail, and hereby waive any and all jurisdictional and venue defenses otherwise
          available. c. The Terms and the relationship between you and Company shall be governed by the laws of the
          State of New York without regard to conflict of law provisions.</p>

          <p class="legal-copy">Governing Law and Jurisdiction. These Terms of Use shall be governed by and construed in accordance with the
          laws of the State of New York, including its conflicts of law rules, and the United States of America. You
          agree that any dispute arising from or relating to the subject matter of these Terms of Use shall be
          governed by the exclusive jurisdiction and venue of the state and Federal courts of New York County, New
          York.</p>

          <p class="legal-copy">Modification. Except with regarding to Section 13, we reserve the right, in our sole discretion, to modify
          or replace any of these Terms of Use, or change, suspend, or discontinue the Services (including without
          limitation, the availability of any feature, database, or content) at any time by posting a notice on the
          App or by sending you notice through the Services, via email or by another appropriate means of electronic
          communication. We may also impose limits on certain features and services or restrict your access to parts
          or all of the Services without notice or liability. While we will timely provide notice of modifications, it
          is also your responsibility to check these Terms of Use periodically for changes. Your continued use of the
          Services following notification of any changes to these Terms of Use constitutes acceptance of those
          changes, which will apply to your continued use of the Services going forward. Your use of the Services is
          subject to the Terms of Use in effect at the time of such use.</p>

          <p class="legal-copy">DMCA Copyright Policy.</p>

          <p class="legal-copy">a. The Company has adopted the following general policy toward copyright infringement
          in accordance with the Digital Millennium Copyright Act. The address of the Designated Agent to Receive
          Notification of Claimed Infringement ("Designated Agent") is listed at the end of this policy. b. Procedure
          for Reporting Copyright Infringement. If you believe that material or content residing on or accessible
          through the Services infringes a copyright, please send a notice of copyright infringement containing the
          following information to the Designated Agent listed below</p>
          <p class="legal-copy">A physical or electronic signature of a person authorized to act on behalf of the owner of the copyright
          that has been allegedly infringed;</p>

          <p class="legal-copy">Identification of works or materials being infringed;</p>

          <p class="legal-copy">Identification of the material that is claimed to be infringing including information regarding the location
          of the infringing materials that the copyright owner seeks to have removed, with sufficient detail so that
          Company is capable of finding and verifying its existence;</p>

          <p class="legal-copy">Contact information about the notifier including address, telephone number and, if available, email
          address;</p>

          <p class="legal-copy">A statement that the notifier has a good faith belief that the material is not authorized by the copyright
          owner, its agent, or the law; and</p>

          <p class="legal-copy">A statement made under penalty of perjury that the information provided is accurate and the notifying party
          is authorized to make the complaint on behalf of the copyright owner.</p>

          <p class="legal-copy">c. Please contact the Designated Agent to Receive Notification of Claimed Infringement for the Company
          at Edward.Russavage@wolfgreenfield.com or at:</p>
          <p class="legal-copy">Marvly, LLC</p>
          <p class="legal-copy">1776 Broadway Unit. 1705</p>
          <p class="legal-copy">Denver, CO 80205</p>
          <p class="legal-copy">Phone: (303) 653-7882</p>
          <p class="legal-copy">Apple Device and Application Terms. In the event you are accessing the Services via an application on a device provided by Apple, Inc. ("Apple") or an application obtained through the Apple App Store (in either case, an “Application”), the following shall apply:</p>
          <p class="legal-copy">a. Both you and the Company acknowledge that these Terms of Use are concluded between you and the Company only, and not with Apple, and that Apple is not responsible for the Application or the Content;</p>

          <p class="legal-copy">b. The Application is licensed to you on a limited, non-exclusive,
          non-transferable, non-sublicensable basis, solely to be used in connection with the Services for your
          private, personal, non-commercial use, subject to all the terms and conditions of these Terms of Use as they are applicable to the Services;</p>

          <p class="legal-copy">c. You will only use the Application in connection with an Apple device that you own or control;</p>

          <p class="legal-copy">d. You acknowledge and agree that Apple has no obligation whatsoever to furnish any
          maintenance and support services with respect to the Application;</p>

          <p class="legal-copy">e. In the event of any failure of the
          Application to conform to any applicable warranty, including those implied by law, you may notify Apple of
          such failure; upon notification, Apple’s sole warranty obligation to you will be to refund to you the
          purchase price, if any, of the Application;</p>

          <p class="legal-copy">f. You acknowledge and agree that the Company, and not Apple, is
          responsible for addressing any claims you or any third party may have in relation to the Application;</p>

          <p class="legal-copy">g. You
          acknowledge and agree that, in the event of any third party claim that the Application or your possession
          and use of the Application infringes that third party’s intellectual property rights, the Company, and not
          Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement
          claim;</p>

          <p class="legal-copy">h. You represent and warrant that you are not located in a country subject to a U.S. Government
          embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country, and that
          you are not listed on any U.S. Government list of prohibited or restricted parties;</p>

          <p class="legal-copy">i. Both you and the
          Company acknowledge and agree that, in your use of the Application, you will comply with any applicable
          third party terms of Agreement which may affect or be affected by such use; and</p>

          <p class="legal-copy">j. Both you and the Company
          acknowledge and agree that Apple and Apple’s subsidiaries are third party beneficiaries of these terms, and
          that upon your acceptance of these terms, Apple will have the right (and will be deemed to have accepted the
          right) to enforce these terms against you as the third-party beneficiary hereof.</p>

          <p class="legal-copy">Miscellaneous.</p>
          <p class="legal-copy">a. Entire Agreement and Severability. These Terms of Use are the entire Agreement between you
          and us with respect to the Services, including use of the App, and supersede all prior or contemporaneous
          communications and proposals (whether oral, written or electronic) between you and us with respect to the
          Services. If any provision of these Terms of Use is found to be unenforceable or invalid, that provision
          will be limited or eliminated to the minimum extent necessary so that these Terms of Use will otherwise
          remain in full force and effect and enforceable. The failure of either party to exercise in any respect any
          right provided for herein shall not be deemed a waiver of any further rights hereunder</p>

          <p class="legal-copy">b. Force Majeure. We
          shall not be liable for any failure to perform our obligations hereunder where such failure results from any
          cause beyond our reasonable control, including, without limitation, mechanical, electronic or communications
          failure or degradation.</p>

          <p class="legal-copy">c. Assignment. These Terms of Use are personal to you, and are not assignable,
          transferable or sublicensable by you except with our prior written consent. We may assign, transfer or
          delegate any of our rights and obligations hereunder without consent. d. Agency. No agency, partnership,
          joint venture, or employment relationship is created as a result of these Terms of Use and neither party has
          any authority of any kind to bind the other in any respect.</p>

          <p class="legal-copy">e. Notices. Unless otherwise specified in these
          Term of Service, all notices under these Terms of Use will be in writing and will be deemed to have been
          duly given when received, if personally delivered or sent by certified or registered mail, return receipt
          requested; when receipt is electronically confirmed, if transmitted by facsimile or email; or the day after
          it is sent, if sent for next day delivery by recognized overnight delivery service. Electronic notices
          should be sent to nathan@taptaprevolution.com.</p>

          <p class="legal-copy">f. No Waiver. Our failure to enforce any part of these Terms of Use shall not
          constitute a waiver of our right to later enforce that or any other part of these Terms of Use. Waiver of
          compliance in any particular instance does not mean that we will waive compliance in the future. In order
          for any waiver of compliance with these Terms of Use to be binding, we must provide you with written notice
          of such waiver through one of our authorized representatives.</p>

          <p class="legal-copy">g. Headings. The section and paragraph
          headings in these Terms of Use are for convenience only and shall not affect their
          interpretation.</p>

          <p class="legal-copy">h. Relationships. The App is not sponsored, endorsed, administered by, or associated with
          Apple or its subsidiaries or affiliates.</p>


          <p class="legal-copy">Contact. You may contact us at the following address: </p>
          <p class="legal-copy">Marvly, LLC, 1776 Broadway, Unit 1705, Denver, Colorado 80205. Effective Date of Terms of Use: March 6th, 2020</p>
          <p class="legal-copy">GDSVF&H\u....855710.4</p>
        </div>
      </main>
    </StyledContainer>
  )
}
