import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion/dist/framer-motion';

import { useInView } from '../../hooks/use-in-view';

export const AnimatedContainer = ({
  children, initial, duration = 0.8, delay = 0.1, className, phase, maxOpacity, style
}) => {
  const controls = useAnimation();
  const rootRef = useRef();
  const onScreen = useInView(rootRef);

  useEffect(() => {
    if (onScreen) {
      controls.start({
        y: 0,
        x: 0,
        scale: 1,
        opacity: maxOpacity ?? 1,
        transition: {
          duration,
          ease: 'easeOut',
          delay,
        },
      });
    }
  }, [onScreen, controls]);

  return (
    <motion.div
      className="lazy-div"
      initial={initial}
      animate={controls}
      ref={rootRef}
      className={className}
      data-phase={phase}
      style={style}
    >
      {children}
    </motion.div>
  );
};
