import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { WalletProvider } from './context/wallet-provider';
import {
  Home,
  Roadmap,
  Manifesto,
  PublicMint,
  Kyc,
} from './pages';

import { TermsAndConditions } from './pages/legal/terms-and-conditions';

const App = () => (
  <>
    <WalletProvider>
      <Router>
        <Switch>
          <Route path="/what-is-ttr">
            <Manifesto />
          </Route>
          <Route path="/roadmap">
            <Roadmap />
          </Route>
          <Route path="/gen1-mint">
            <PublicMint />
          </Route>
          <Route path="/legal/terms-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/kyc">
            <Kyc />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </WalletProvider>
  </>
);

export default App;
