import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useMemo,
} from 'react'
import styled, { css } from 'styled-components'

import { globalColors } from '@ttr/tokens/colors'

import { IphoneHome } from '../components'
import { AnimatedContainer, PageContainer, Nav } from '../components'

const CODE = 'TTR-8897-KYCV-1E91';

const ButtonCss = css`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  height: 60px;
  min-height: 60px;
  border-radius: 75px;
  margin-bottom: 5px;
  // min-width: 335px;
  max-width: 335px;
  width: 100%;
  border: none;
  outline: none;
  background: ${globalColors.ttrDarkBlue};
  border: 2px solid transparent;
  color: ${globalColors.ttrWhite};
  text-transform: uppercase;
  text-align: center;
  font-size: 19px;
  padding: 0 30px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: transparent;
    border-color: ${globalColors.ttrDarkBlue};
    color: ${globalColors.ttrDarkBlue};
  }
`;

const MintWrapper = styled(AnimatedContainer)`
  border: 2px solid ${globalColors.ttrDarkBlue};
  background: rgba(69, 159, 248, 0.85);
  width: 30%;
  margin: 0 10px;
  height: 95%;
  // max-height: 525px;
  justify-content: flex-start;
  position: relative;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-left: 65px;
  min-width: 500px;

  &:before {
    color: ${globalColors.ttrWhite};
    content: attr(data-phase);
    background: ${globalColors.ttrDarkBlue};
    display: flex;
    position: absolute;
    top: -24px;
    left: 0;
    padding: 0 30px 0 20px;
    clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
  }

  &:after {
    content: '';
    clip-path: polygon(10% 0, 0 100%, 100% 100%, 100% 100%, 75% 0);
    background: rbga(0, 0, 0, 0.2);
    position: absolute;
  }

  @media (max-width: 1080px) {
    width: 80%;
    margin-left: 0;
  }

  @media (max-width: 720px) {
    width: 90%;
    min-width: 280px;
    height: 85%;
    margin-right: 0;
  }
`

const MintContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 40px 0 0;
  justify-content: center;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;

  @media (max-width: 1080px) {
    flex-wrap: wrap;
    // overflow: auto;
    height: 90%;
    padding: 40px 0px 0;
    max-height: 100%;
  }

  @media (max-width: 720px) {
    // margin-top: 60px;
    height: 100%;
    margin: 0;
    padding: 100px 0px 0;
  }
`

const PhaseTitle = styled.h2`
  color: ${globalColors.ttrWhite};
  font-size: 45px;
  margin-bottom: 20px;
  margin: 0;
  font-weight: 700;
  letter-spacing: -2px;
  text-transform: uppercase;
  border-bottom: 2px solid ${globalColors.ttrDarkBlue};
  padding: 20px 20px 20px;

  @media (max-width: 420px) {
    font-size: 33px;
  }
`

const StyledSubmitButton = styled.button`
  ${ButtonCss}
`

const StyledKycLink = styled.a`
  ${ButtonCss}
`

const ContentWrapper = styled.div`
  height: 625px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
`

const StyledIphone = styled(IphoneHome)`
  @media (max-width: 1080px) {
    display: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 30px;
  height: 100%;
  width: 100%;
`;

const StyleInput = styled.input`
  padding: 10px 20px;
  border-radius: 8px;
  opacity: 0.85;
  border: 1px solid transparent;
  outline: none;
  width: 100%;
  max-width: 350px;
  min-width: 250px;
  margin-bottom: 20px;

  &.error {
    border-color: red;
  }
`;

const InfoText = styled.p`
  color: ${globalColors.ttrWhite};
`;

export const Kyc = () => {
  const [code, setCode] = useState('');
  const [showCodeError, setShowCodeError] = useState(false);
  const [showGatedContent, setShowGatedContent] = useState(false);

  const handleCodeChange = useCallback((e) => {
    setCode(e.target.value);
  }, [setCode]);


  const handleSubmit = useCallback(() => {
    setShowCodeError(false);

    if (code === CODE) {
      setShowGatedContent(true);
      return true;
    }

    setShowCodeError(true);
    return false;
  }, [code, setShowGatedContent, setShowCodeError]);

  const handleCodeEnter = useCallback(() => {
    if(event.key === 'Enter') {
      setShowCodeError(false);

      if (code === CODE) {
        setShowGatedContent(true);
        return true;
      }

      setShowCodeError(true);
      return false;
    }
  }, [code, setShowGatedContent, setShowCodeError]);


  return (
    <PageContainer>
      <Nav />
      <MintContainer>
        <ContentWrapper>
          <StyledIphone show />
          <MintWrapper phaase="Mint">
            <PhaseTitle>KYC Verification</PhaseTitle>
            <Wrapper>
              {!showGatedContent && (
                <>
                  {showCodeError && (
                    <p>Please enter a valide code.</p>
                  )}
                  <StyleInput
                    placeholder="Code"
                    onChange={handleCodeChange}
                    onKeyDown={handleCodeEnter}
                    value={code}
                    className={showCodeError ? 'error' : ''}
                  />
                  <StyledSubmitButton onClick={handleSubmit}>Submit</StyledSubmitButton>
                </>
              )}
              {showGatedContent && (
                <>
                  <InfoText>Identity Verification Below </InfoText>
                 <StyledKycLink target="_blank" href="https://in.sumsub.com/idensic/l/#/uni_1QOhRvQj3bKjzgI0">Verify</StyledKycLink>
                </>
              )}
            </Wrapper>
          </MintWrapper>
        </ContentWrapper>
      </MintContainer>
    </PageContainer>
  )
}
