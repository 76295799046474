import React from 'react';
import styled from 'styled-components';

const StyledCopy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 80px;
  min-height: 200px;

  &.left-align {
    text-align: left;
  }

  @media(max-width: 720px) {
    padding: 30px 10px;
  }
`;

const StyledContainer = styled.div`
  // width: 720px;
`;

export const Copy = ({ children, background }) => (
  <StyledCopy background={background}>
    <StyledContainer>
      { children }
    </StyledContainer>
  </StyledCopy>
);
