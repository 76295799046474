import React from 'react';
import styled from 'styled-components';
import { globalColors } from '@ttr/tokens/colors';

const StyledCopy = styled.div`
  background: ${(props) => (props.background ? props.background : null)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 30px;
  min-height: 200px;

  &.left-align {
    text-align: left;
  }

  .text-callout {
    // @media(max-width: 720px) {
    //   text-align: left;
    // }
  }

  @media(max-width: 720px) {
    padding: 50px 30px;
  }
`;

const StyledContainer = styled.div`
  width: 720px;
`;

export const Copy = ({ children, background }) => (
  <StyledCopy background={background}>
    <StyledContainer>
      { children }
    </StyledContainer>
  </StyledCopy>
);
