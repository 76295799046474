import React from 'react';
import styled from 'styled-components';
import {
  AnimatedContainer,
  PageContainer,
  Logo,
  Nav,
} from '../components';
import { globalColors } from '@ttr/tokens/colors';
import { Link } from 'react-router-dom';

const StyledLogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  padding: 20px 0 0;
  z-index: 3;

  @media (max-width: 720px) {
    margin-bottom: 0px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;

const StyledLogo = styled(Logo)`
  img {
    @media (max-width: 720px) {
      margin-bottom: 5px;
    }
  }
`

const Title = styled.h1`
  color: ${globalColors.ttrDarkGreen};
  font-size: 30px;
  line-height: 32px;

  @media (max-width: 720px) {
    margin-top: 0;
  }
`;

const StyledConnectButton = styled(Link)`
  flex: 1;
  justify-content: center;
  display:flex;
  text-decoration: none;
  align-items: center;
  max-height: 60px;
  height: 52px;
  min-height: 52px;
  border-radius: 75px;
  margin-bottom: 5px;
  // min-width: 335px;
  max-width: 335px;
  width: 100%;
  border: none;
  outline: none;
  background: ${globalColors.ttrDarkGreen};
  border: 2px solid transparent;
  color: ${globalColors.ttrGreen};
  text-transform: uppercase;
  text-align: center;
  font-size: 19px;
  padding: 0 30px;
  font-weight: 500;
  cursor: pointer;
  margin-bottom: 60px;

  &:first-of-type {
    margin-bottom: 10px;
  }

  &:hover {
    background: transparent;
    border-color: ${globalColors.ttrDarkGreen};
    color: ${globalColors.ttrDarkGreen};
  }
`

export const Home = () => (
  <PageContainer>
    <Nav />
    <LogoContainer>
      <AnimatedContainer initial={{ opacity: 0 }}>
        <StyledLogoWrapper>
          <StyledLogo show />
          <Title>MINTING NOW!</Title>
          <StyledConnectButton to="/gen1-mint">Mint</StyledConnectButton>
        </StyledLogoWrapper>
      </AnimatedContainer>
    </LogoContainer>
  </PageContainer>
);
